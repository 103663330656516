import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AppLogo from "./assets/phalanxapplogo.svg";
import JirinaLoginImage from "./assets/JirinaLoginImage.png";
import theme from "../../constants/Theme";
import {
  isEmptyOrNull,
  isValidEmail,
  isValidPassword,
} from "../../utils/inputvalidation";
import services from "../../services";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  setAuthData,
  showLoader,
  toggleSnackBar,
} from "../../modules/actions";
import { useNavigate } from "react-router-dom";
import commontext from "../../constants/commontext";
import ROUTES_PATH from "../../constants/routes";
import AllIcons from "../../constants/icons/icons";

const styles = {
  imageStyle: {
    borderRadius: "10px",
    backgroundImage: `url(${JirinaLoginImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "705px",
  },

  centeredContent: {
    backgroundColor: `${theme.palette.mainContainer.greyBackground}`,
    minHeight: "100vh",
    display: "grid",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    flexFlow: "row wrap",
    width: 1,
    webkitBoxPack: "center",
    justifyContent: "center",
  },
};

//Email : devadmin@yopmail.com
//Passwd: qwerty@123

const Login = () => {
  const [values, setValues] = React.useState({
    password: "",
    email: "",
    showPassword: false,
    error: true,
    isEmailError: false,
    isPasswordError: false,
    emailErrorMsg: "",
    passwordErrorMsg: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validate()) {
      dispatch(showLoader());
      let { email, password } = values;
      services
        .loginWithEmailPassword(email, password)
        .then(res => {
          dispatch(hideLoader());
          if (!isEmptyOrNull(res?.message)) {
            showSnackBar(res?.message);
          }
          if (res.success) {
            dispatch(setAuthData({ userData: res.response, error: null }));
            localStorage.setItem(commontext.isLogin, "1");
            dispatch(hideLoader());
            navigate(ROUTES_PATH.DASHBOARD);
          }
        })
        .catch(error => {
          dispatch(hideLoader());
          dispatch(setAuthData({ userData: null, error: error }));
          console.log("error::", error);
          if (error?.response) {
            const errorCode = error?.response.code;
            if (errorCode == "auth/user-not-found") {
              showSnackBar(commontext.userNotFound);
            } else if (errorCode == "auth/wrong-password") {
              showSnackBar(commontext.passwordCorrect);
            } else {
              showSnackBar(commontext.somethingWentWrong);
            }
          }
        });
    }
  };

  const showSnackBar = message => {
    dispatch(
      toggleSnackBar({
        showSnackBar: true,
        message: message,
      })
    );
  };

  const validate = () => {
    let { email, password } = values;
    if (email == "") {
      handleChangeValue("isEmailError", true);
      handleChangeValue("emailErrorMsg", commontext.emailRequired);
      return false;
    } else if (!isValidEmail(email)) {
      handleChangeValue("isEmailError", true);
      handleChangeValue("emailErrorMsg", commontext.validEmailRequired);
      return false;
    } else if (password == "") {
      handleChangeValue("isPasswordError", true);
      handleChangeValue("passwordErrorMsg", commontext.passwordRequired);
      return false;
    } else if (!isValidPassword(password)) {
      handleChangeValue("isPasswordError", true);
      handleChangeValue("passwordErrorMsg", commontext.validPasswordRequired);
      return false;
    } else {
      return true;
    }
  };

  return (
    <Stack sx={styles.centeredContent}>
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: theme.breakpoints.values.xl }}
      >
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Container maxWidth="sm">
            <Box
              pt={{ lg: 15, xs: 10 }}
              pb={{ xs: 2 }}
              px={{ xl: 7, lg: 5, md: 5, sm: 1, xs: 1 }}
            >
              <img src={AppLogo} className="companyLogo" alt="Company-Logo" />
              <Typography
                variant="signin"
                component="div"
                mt={4}
                sx={{
                  color: theme => theme.palette.common.white,
                }}
              >
                {commontext.signIn}
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormControl sx={{ mt: 1, width: 1 }}>
                  <TextField
                    label={commontext.email}
                    sx={{ mt: 3 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AllIcons.MailOutlineIcon
                            sx={{
                              color: `${theme.palette.icon.iconColor}`,
                              fontSize: "18px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    onFocus={() => {
                      handleChangeValue("isEmailError", false);
                      handleChangeValue("emailErrorMsg", "");
                    }}
                    variant="standard"
                    placeholder={commontext.enterEmailAddress}
                    onChange={handleChange("email")}
                    fullWidth
                    error={values.isEmailError}
                    helperText={values.emailErrorMsg}
                  />

                  <TextField
                    variant="standard"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AllIcons.LockIcon
                            sx={{
                              color: `${theme.palette.icon.iconColor}`,
                              fontSize: "18px",
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <AllIcons.VisibilityOff />
                            ) : (
                              <AllIcons.Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label={commontext.password}
                    placeholder={commontext.enterPassword}
                    fullWidth
                    sx={{ mt: 6 }}
                    error={values.isPasswordError}
                    helperText={values.passwordErrorMsg}
                    onFocus={() => {
                      handleChangeValue("isPasswordError", false);
                      handleChangeValue("passwordErrorMsg", "");
                    }}
                  />
                  <Button type="submit" sx={{ mt: 10 }}>
                    {commontext.login}
                  </Button>
                </FormControl>
              </form>
            </Box>
          </Container>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box my={2} mx={2} style={styles.imageStyle}></Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Login;
