import * as React from "react";
import commontext from "../../constants/commontext";
import theme from "../../constants/Theme";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "../../constants/Theme/styles";
import AllIcons from "../../constants/icons/icons";
import { Grid, Typography, IconButton } from "@mui/material";

export default function UserPreventionModal({ handleClose, isOpen }) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="logout-dialog"
      fullWidth
      maxWidth="sm"
      onBackdropClick="false"
    >
      <DialogTitle id="logout-dialog" sx={{ fontSize: 25, pb: 0 }}>
        <Grid className={classes.dialogCloseBtn}>
          <Typography variant="div">{commontext.uploadInProgress}</Typography>
          <IconButton onClick={handleClose}>
            <AllIcons.CloseIcon
              sx={{ color: theme => theme.palette.common.black, fontSize: 29 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{commontext.doNotLeavePage}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
