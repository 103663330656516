import { themePalette } from "./colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  uploadModalDisabledFieldStyle: {
    "& .MuiFormLabel-root.Mui-disabled": {
      color: themePalette.palette.disabledButton.btnBg,
      opacity: 0.6,
    },
    "& .MuiInput-input.Mui-disabled": {
      fontSize: 17,
    },
    "& .MuiInput-root.Mui-disabled": {
      marginTop: 20,
      marginBottom: 5,
    },
    "& .MuiInputBase-root.MuiInput-root:before": {
      borderBottom: `3px solid ${themePalette.palette.disabledButton.btnBg} !important`,
      opacity: 0.4,
    },
  },

  uploadModalActiveFieldStyle: {
    "& .MuiInputBase-root": {
      marginTop: 20,
      marginBottom: 5,
    },
    "& .MuiInputBase-root.MuiInput-root:before": {
      borderBottom: `3px solid ${themePalette.palette.disabledButton.btnBg} !important`,
    },
    "& .MuiInput-input": {
      color: themePalette.palette.common.black,
    },
    "& .Mui-focused": {
      fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
      color: `${themePalette.palette.common.black} !important`,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: `${themePalette.palette.common.black} !important`,
    },
  },

  dialogCloseBtn: {
    "& .MuiIconButton-root": {
      float: "right",
    },
  },

  addVideoBtn: {
    "& .MuiButtonBase-root.MuiButton-root": {
      "@media (max-width:1200px) ": {
        fontSize: 15,
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
  },

  selectedVideoFile: {
    "& .MuiTypography-body1": {
      fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
      color: themePalette.palette.disabledButton.btnBg,
      fontSize: 14,
    },
    "& .MuiBox-root": {
      fontSize: 17,
      fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
    },
    "& .MuiButton-root": {
      "&:hover": {
        // !important attribute to override the default background
        // color and color text on hover trash icon
        backgroundColor: "unset !important",
        color: `${themePalette.palette.disabledButton.btnBg} !important`,
        boxShadow: "unset",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: 25,
    },
  },

  uploadVideoContent: {
    "& .MuiDialogTitle-root": {
      padding: "0 0 10px",
    },
  },

  centerScreenText: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "67vh",

    "& .MuiTypography-root": {
      fontSize: 30,
    },
  },
}));

export default useStyles;
