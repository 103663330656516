import { Navigate, Outlet } from "react-router-dom";
import commontext from "../constants/commontext";
import ROUTES_PATH from "../constants/routes";

export const PublicRoute = () => {
    const authData = localStorage.getItem(commontext.isLogin);
    if (authData) {
        return <Navigate to={ROUTES_PATH.DASHBOARD} replace />;
    }
    return (
        <div>
            <Outlet />
        </div>
    )
}

export const ProtectedRoute = () => {
    const authData = localStorage.getItem(commontext.isLogin);
    if (!authData) {
        return <Navigate to={ROUTES_PATH.LOG_IN} replace />;
    }
    return (
        <div>
            <Outlet />
        </div>
    )
}