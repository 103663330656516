import { Backdrop, Box, CircularProgress, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const styles = {
  backdropStyle: {
    zIndex: theme => theme.zIndex.drawer + 1,
    display: "flex",
    position: "absolute",
    minHeight: "100vh",
    width: 1,
    alignItems: "center",
    justifyContent: "center",
    top: 0,
  },
  loaderStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: 1,
  },
};

export default function Loader() {
  const theme = useTheme();
  const isLoading = useSelector(state => state.app.loading);
  if (isLoading) {
    return (
      <Backdrop open={true} sx={styles.backdropStyle}>
        <CircularProgress
          size={"60px"}
          sx={{
            color: theme.palette.common.white,
          }}
        />
      </Backdrop>
    );
  } else {
    return null;
  }
}

export function CustomLoader({ isLoading }) {
  const theme = useTheme();
  if (isLoading) {
    return (
      <Box sx={styles.loaderStyle}>
        <CircularProgress
          size={"60px"}
          sx={{
            color: theme.palette.common.black,
          }}
        />
      </Box>
    );
  } else {
    return null;
  }
}
