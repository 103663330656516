import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeInputs = createTheme(themePalette, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // important attribute is added as to ovveride the default bottom-border color "before" and "after" an input field is focused
          ":before": {
            borderBottom: `2px solid ${themePalette.palette.inputField.inputBeforeColor}  !important`,
          },
          ":after": {
            borderBottom: `2px solid ${themePalette.palette.inputField.inputAfterColor} !important`,
          },
        },
        input: {
          fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
          paddingBottom: "9px",
          color: themePalette.palette.inputField.inputTextColor,
          paddingTop: "8px",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
          color: themePalette.palette.inputFieldLabel.labelColor,
          fontSize: "18px",
          "&.Mui-focused": {
            color: themePalette.palette.inputFieldLabel.focusedLabel,
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: themePalette.palette.inputField.eyeIconColor,
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
        fontSizeMedium: {
          fontSize: "1.3rem",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
          color: themePalette.palette.errorMsg.main,
          fontSize: "0.8rem",
        },
      },
    },
  },
});
