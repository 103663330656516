import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './Common.css';

export default function CategoryDropdown(props) {
  

  return (
    <div>

      <FormControl sx={{ marginBottom: 1,minWidth: 300, float:"right" }} >
        <InputLabel id="helper-label" style={{color:"#FFFFFF", fontSize: "15px"}}>Filter by category</InputLabel>
        <Select
          sx={{
            color: "#FFFFFF",
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(228, 219, 233, 0.25)',
            },
            '.MuiSvgIcon-root ': {
              fill: "#FFFFFF",
            }
          }}
          labelId="helper-label"
          id="demo-simple-select-helper"
          value={props.onSelectValue}
          label="Filter by category  "
          onChange={props.change}
        >
          <MenuItem value={'beginner'}>Beginner</MenuItem>
          <MenuItem value={'intermediate'}>Intermediate</MenuItem>
          <MenuItem value={'advanced'}>Advanced</MenuItem>
          <MenuItem value={'expert'}>Expert</MenuItem>
          <MenuItem value={'miscellaneous'}>Miscellaneous</MenuItem>
          <MenuItem value={'all'}>All</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
