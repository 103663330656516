import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeTypography = createTheme(themePalette, {
  typography: {
    signin: {
      fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
      fontSize: "32px",
      lineHeight: "45px",
      "@media (max-width:800px)": {
        fontSize: "25px",
      },
    },

    uploadTitle: {
      fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
      fontSize: 18,
    },
    uploadSubtitle: {
      fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
    },
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
          fontSize: 17,

          "@media (max-width:1200px)": {
            fontSize: 14,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 15,
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          ":last-child": {
            p: {
              fontFamily: '"PoppinsExtraBold", "Helvetica", "Arial", sans-serif',
            },
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        message: {
          fontFamily: '"PoppinsSemiBold", "Helvetica", "Arial", sans-serif',
          opacity: 0.5,
          fontSize: 14,
        },
      },
    },
  },
});
