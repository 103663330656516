import React, {
  useEffect,
  useState,
} from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { TableWrapper } from "../style";
import commontext from "../../../constants/commontext";
import services from "../../../services";
import Row from "./PromoCodeRow";
import { getUUID } from "../../../utils/helper";
import { hideLoader, showLoader, toggleSnackBar } from "../../../modules/actions";
import { useDispatch } from "react-redux";

const PromoCodesAccordion = () => {
  const [previous, setPrevious] = React.useState({});
  const [allPromoData, setAllPromoData] = useState([]);
  const [promoCodeAsPack, setPromoCodeAsPack] = useState([]);
  const [randomNumber, setRandom] = useState(Math.floor(Math.random() * 10));
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllPromoList();
  }, [])

  const getAllPromoList = () => {
    try {
      dispatch(showLoader());
      services.getAllListOfPromoTable()
        .then(response => {
          if (response.success) {
            setAllPromoData(response.response)

            let array = [...response.response]
            const arrayByPacks = array.reduce(
              (obj, { pack, discount, code, status, id, allowed_times }) => {
                if (!obj.hasOwnProperty(pack)) {
                  obj[pack] = { pack, discount_list: [] };
                }
                obj[pack].discount_list = [
                  ...obj[pack].discount_list,
                  { pack, discount, code, status, id, allowed_times }
                ];
                return obj;
              },
              {}
            );
            let finalList = Object.values(arrayByPacks);
            let sortedPackList = finalList.sort((a, b) => {
              return a.pack - b.pack
            })
            let sortedDiscountList = sortedPackList.map((obj) => {
              let discount_list = [...obj.discount_list];
              let sortedList = discount_list.sort((a, b) => a.discount - b.discount)
              return {
                ...obj,
                discount_list: sortedList
              }
            })
            dispatch(hideLoader());

            setPromoCodeAsPack(sortedDiscountList)
            setRandom(Math.floor(Math.random() * 10))
          }
          else {
            dispatch(hideLoader());
          }
        }).catch((error) => {
          dispatch(hideLoader());
        })
    } catch (error) {
      dispatch(hideLoader());
    }
  }

  const onToggleEditMode = (discountIndex, packIndex, toggleValue) => {
    let promoCodeAsPackUpdatedList = promoCodeAsPack;
    let updatedDiscountList = promoCodeAsPackUpdatedList[packIndex].discount_list
    // updatedDiscountList = updatedDiscountList.map((obj) => { return { ...obj, isEditMode: false } })
    updatedDiscountList[discountIndex].isEditMode = toggleValue;
    promoCodeAsPackUpdatedList[packIndex].discount_list = updatedDiscountList
    if (toggleValue) {
      setIsEditModeOn(true)
    }
    else {
      setIsEditModeOn(false)
    }
    setPromoCodeAsPack(promoCodeAsPackUpdatedList);
    setRandom(Math.floor(Math.random() * 10))
  };

  const onUpdateRow = (discountIndex, packIndex, value, row, id, isSwitchOn, allowedTimes) => {
    try {
      dispatch(showLoader());

      let allPromoDataUpdated = allPromoData;
      let selectedValue = allPromoDataUpdated.find((obj) => obj.id == id)
      let finalPromo = {
        ...selectedValue,
        code: value,
        status: isSwitchOn,
        allowed_times: allowedTimes ? Number(allowedTimes) : 1
      }
      let uniq_id = finalPromo.id ? finalPromo.id : getUUID();

      let promoCodeAsPackUpdatedList = promoCodeAsPack;
      let updatedDiscountList = promoCodeAsPackUpdatedList[packIndex].discount_list

      updatedDiscountList[discountIndex].isEditMode = false;
      updatedDiscountList[discountIndex].code = value;
      updatedDiscountList[discountIndex].status = isSwitchOn;
      updatedDiscountList[discountIndex].allowed_times = allowedTimes ? Number(allowedTimes) : 1;

      promoCodeAsPackUpdatedList[packIndex].discount_list = updatedDiscountList

      setPromoCodeAsPack(promoCodeAsPackUpdatedList);
      setRandom(Math.floor(Math.random() * 10))
      setIsEditModeOn(false)

      services.setPromoToStoreList(finalPromo, uniq_id).then(res => {
        dispatch(hideLoader());
        if (res.success) {
          showSnackBar(commontext.promoCodeUpdatedSuccessfully);
          getAllPromoList();
        }
      }).catch(error => {
        dispatch(hideLoader());
        showSnackBar(commontext.somethingWentWrong);
      });
    } catch (error) {
      dispatch(hideLoader());
    }
  }

  const showSnackBar = message => {
    dispatch(
      toggleSnackBar({
        showSnackBar: true,
        message: message,
      })
    );
  };

  const onRevert = id => {
    const newRows = promoCodeAsPack.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setPromoCodeAsPack(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    // onToggleEditMode(id);
  };

  return (
    <TableWrapper>
      <TableContainer component={Paper}>
        <Table stickyHeader={true}>

          <TableBody>
            {promoCodeAsPack.map((row, index) => (
              <Row
                row={row}
                editToggle={(discountIndex, toggleValue) => onToggleEditMode(discountIndex, index, toggleValue)}
                rowIndex={index}
                onUpdateRow={(discountIndex, value, id, isSwitchOn, allowedTimes) => onUpdateRow(discountIndex, index, value, row, id, isSwitchOn, allowedTimes)}
                allPromoData={allPromoData}
                isEditModeOn={isEditModeOn}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default PromoCodesAccordion;
