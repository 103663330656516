import * as React from "react";
import commontext from "../../constants/commontext";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  resetReduxStore,
  showLoader,
  toggleSnackBar,
} from "../../modules/actions";
import ROUTES_PATH from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import services from "../../services";
import theme from "../../constants/Theme";
import AppLogo from "../../pages/login-form/assets/phalanxapplogo.svg";
import { Box, AppBar, Toolbar, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "../../constants/Theme/styles";

export default function AppHeader({ isVideoInProgress, showWarningModal }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickOpen = () => {
    if (isVideoInProgress) showWarningModal();
    else setOpen(true);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const onSignoutClick = () => {
    handleClose();
    dispatch(showLoader());

    setTimeout(() => {
      services
        .signOutFromApp()
        .then(response => {
          if (response.success) {
            localStorage.clear();
            dispatch(resetReduxStore());
            dispatch(hideLoader());
            navigate(ROUTES_PATH.LOG_IN);
          }
        })
        .catch(error => {
          dispatch(hideLoader());
          showSnackBar(commontext.somethingWentWrong);
        });
    }, 300);
  };
  const showSnackBar = message => {
    dispatch(
      toggleSnackBar({
        showSnackBar: true,
        message: message,
      })
    );
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: theme => theme.palette.appHeader.gradientBg,
        boxShadow: "unset",
      }}
    >
      <Toolbar>
        <Box
          sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          my={2}
          mx={1}
        >
          <img
            src={AppLogo}
            className="companyLogo"
            alt="Company-Logo"
            width={110}
          />
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Button variant="text" sx={{ mb: 1 }} onClick={handleClickOpen}>
            Logout
          </Button>
        </Box>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="logout-dialog"
          fullWidth
          maxWidth="sm"
          onBackdropClick="false"
        >
          <DialogTitle id="logout-dialog">{commontext.logout}</DialogTitle>
          <DialogContent>
            <DialogContentText>{commontext.areYouSureLogOut}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={handleClose}
              sx={{ mt: 0, fontSize: 15 }}
              variant="logout-option"
            >
              {commontext.cancel}
            </Button>
            <Button
              onClick={onSignoutClick}
              autoFocus
              sx={{ mt: 0, fontSize: 15 }}
              variant="logout-option"
            >
              {commontext.yes}
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
}
