import React, { useReducer, useLayoutEffect } from "react";
import { v4 } from "uuid";
import { ThemeProvider } from "styled-components";

import { useDidMountEffect } from "../../utils/treeutils";
import { TreeContext, reducer } from "./state";
import { StyledTree } from "./Tree.style";
import { Folder } from "./Folder/TreeFolder";
import { File } from "./File/TreeFile";

const Tree = ({ children, data, onNodeClick, onUpdate, onAddButtonClick, onEditButtonClick }) => {
  const [state, dispatch] = useReducer(reducer, data);

  useLayoutEffect(() => {
    dispatch({ type: "SET_DATA", payload: data });
  }, [data]);

  useDidMountEffect(() => {
    onUpdate && onUpdate(state);
  }, [state]);

  const isImparative = data && !children;

  return (
    <ThemeProvider theme={{ indent: 20 }}>
      <TreeContext.Provider
        value={{
          isImparative,
          state,
          dispatch,
          onNodeClick: node => {
            onNodeClick && onNodeClick(node);
          },
        }}
      >
        <StyledTree>
          {isImparative ? (
            <TreeRecusive
              data={state}
              parentNode={state}
              onAddButtonClick={onAddButtonClick}
              onEditButtonClick={onEditButtonClick}
            />
          ) : (
            children
          )}
        </StyledTree>
      </TreeContext.Provider>
    </ThemeProvider>
  );
};

const TreeRecusive = ({ data, parentNode, onAddButtonClick, onEditButtonClick }) => {
  let finalData = data?.workouts ? data.workouts : data;

  return finalData.map(item => {
    item.parentNode = parentNode;
    const folderTitle = item?.title && item?.workouts ? true : false;
    const newFolder = item?.data?.title;

    if (!parentNode) {
      item.parentNode = data;
    }

    if (!item.id) item.id = v4();
    if (item?.videoTitle) return null;

    let name = item?.workoutType || item?.title || item?.workoutName || "";
    let dataTemp = item?.workouts || item?.data || item?.workoutVideos || [];

    let folderActions = item?.workoutName ? false : true
    let showEditIcon = item?.workoutType ? false : true;
    return (
      <Folder
        key={item.id}
        id={item.id}
        name={name}
        node={item}
        folderActions={folderActions}
        onAddButtonClick={onAddButtonClick}
        onEditButtonClick={onEditButtonClick}
        showEditIcon={showEditIcon}
      >
        <TreeRecusive
          parentNode={item}
          data={dataTemp}
          onAddButtonClick={onAddButtonClick}
          onEditButtonClick={onEditButtonClick}
        />
      </Folder>
    );
  });
};

Tree.File = File;
Tree.Folder = Folder;

export default Tree;
