import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeTable = createTheme(themePalette, {
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "unset !important",
          // !important attribute so as to override the default table box-shadow
          borderRadius: "1rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          // !important attribute so as to override the default table header
          // background and text color
          backgroundColor: "unset !important",
          color: `${themePalette.palette.tableHeader.titleColor} !important`,
          //fontSize: 15,
          padding: "17px",
          borderBottom: `2px solid ${themePalette.palette.tableHeader.bottomBorder}`,
        },
        root: {
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          fontSize: 15,
          padding: "20px 21px",

          "@media (max-width:1200px) ": {
            fontSize: 13,
          },
        },
        body: {
          borderBottom: "unset",
          "@media (max-width:1200px) ": {
            fontSize: "13px !important",
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingTop: "15px",
          paddingRight: "20px !important",
          paddingBottom: "10px",
        },
        selectLabel: {
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        },

        select: {
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          color: themePalette.palette.common.black,
          backgroundColor:
            themePalette.palette.paginationSelect.dropdownBackground,
          padding: "7px",
        },

        displayedRows: {
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
        },
      },
    },
  },
});
