import React, { useState } from "react";
import AllIcons from "../../../constants/icons/icons";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import commontext from "../../../constants/commontext";

const PackActions = props => {
  const { row, index, toggleMode, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? "dots-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleClick}
      >
        <AllIcons.MoreHorizIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          key={commontext.promoEditText}
          onClick={() => {
            handleClose();
            toggleMode(index);
          }}
          sx={{
            fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          }}
        >
          {commontext.promoEditText}
        </MenuItem>
      </Menu>
    </>
  );
};

export default PackActions;
