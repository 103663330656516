import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import environmentVariable from '../utils/environmentVariable';

const firebaseConfigForDevelopment = {
    apiKey: "AIzaSyDsV6gc-WYz2iASFqyKVkq-EUlKOolz9PE",
    authDomain: "phalanx-develop.firebaseapp.com",
    databaseURL: "https://phalanx-develop.firebaseio.com",
    projectId: "phalanx-develop",
    storageBucket: "phalanx-develop.appspot.com",
    messagingSenderId: "1015680156116",
    appId: "1:1015680156116:web:9ea8a9124478e426a69641",
    measurementId: "G-G9TR61VTTL"
};

const firebaseConfigForProduction = {
    apiKey: "AIzaSyAp8-woinalmC3aJmMAy3VzZEl1s-4pnHQ",
    authDomain: "phalanx-staging.firebaseapp.com",
    databaseURL: "https://phalanx-staging.firebaseio.com",
    projectId: "phalanx-staging",
    storageBucket: "phalanx-staging.appspot.com",
    messagingSenderId: "321512588612",
    appId: "1:321512588612:web:dbaa4f49cf9aad1aa38311",
    measurementId: "G-E28KLZQB39"
};

const firebaseConfig = environmentVariable.APP_ENVIRONMENT == "DEV" ? firebaseConfigForDevelopment : firebaseConfigForProduction

const app = initializeApp(firebaseConfig);
const FirebaseAnalytics = getAnalytics(app);
const FirebaseAuth = getAuth(app)
const FirestoreDb = getFirestore(app)

const FirebaseApp = app;

export {
    FirebaseAuth,
    FirebaseAnalytics,
    FirebaseApp,
    FirestoreDb,
}