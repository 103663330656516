import React from "react";
import AllIcons from "../../constants/icons/icons";

const FILE_ICONS = {
  js: <AllIcons.JavascriptIcon />,
  css: <AllIcons.CssIcon />,
  html: <AllIcons.HtmlIcon />,
  jsx: <AllIcons.CodeIcon />,
};

export default FILE_ICONS;
