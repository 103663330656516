import { v4 as uuidv4 } from 'uuid';
import AwsCredentials from './awsCredentials';

export const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const getUUID = () => {
    return uuidv4();
}

export const loadVideo = file => new Promise((resolve, reject) => {
    try {
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            resolve(this)
        }

        video.onerror = function () {
            reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file)
    } catch (e) {
        reject(e)
    }
})

export const secondsToTime = (e) => {
    var h = Math.floor(e / 3600)
        .toString()
        .padStart(2, '0'),
        m = Math.floor((e % 3600) / 60)
            .toString()
            .padStart(2, '0'),
        s = Math.floor(e % 60)
            .toString()
            .padStart(2, '0');

    let time =
        h == '00' || h == null || h == undefined
            ? m + ':' + s
            : h + ':' + m + ':' + s;
    return time;
};

export const getFormattedVideoUrl = (keyPath) => {
    let preUrl = keyPath.replace(/ /g, '+');
    const finalUrl = preUrl;
    return finalUrl;
};

export const getKeyPrefix = (formattedKeyPrefix, fileName) => {
    return `${AwsCredentials.bucketName}/${formattedKeyPrefix}/${fileName}.mp4`;
}

export function capFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes == 0) return 'n/a';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (i == 0) return bytes + ' ' + sizes[i];
    return {
        value: Number((bytes / Math.pow(1024, i)).toFixed(1)),
        type: sizes[i]
    }
};

export const getKeyPrefixForMp4 = (formattedKeyPrefix, fileName) => {
    return `${formattedKeyPrefix}/${fileName}.mp4`;
}

export const getKeyPrefixForm3u8 = (formattedKeyPrefix, fileName) => {
    return `${formattedKeyPrefix}/${fileName}.m3u8`;
}

export const getKeyPrefixFormStreamingFolder = (formattedKeyPrefix, fileName) => {
    return `${formattedKeyPrefix}/${fileName}/Default/`;
}