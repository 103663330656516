function createData(discount, code, applicableOn, startDate, endDate) {
  return {
    discount,
    code,
    applicableOn,
    startDate,
    endDate,
    isEditMode: false,
  };
}

export default createData;
