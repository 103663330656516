import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          backgroundColor: themePalette.palette.button.buttonBg,
          color: themePalette.palette.button.buttonText,
          borderRadius: 32,
          textTransform: "none",
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          fontSize: 18,
          lineHeight: "26px",
          padding: "12px 0 12px",
        },
      },

      variants: [
        {
          props: { variant: "contained" },
          style: {
            "&:hover": {
              backgroundColor: themePalette.palette.button.buttonHoverBg,
              color: themePalette.palette.button.buttonBg,
              boxShadow:
                "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            fontFamily: '"PoppinsLight", "Helvetica", "Arial", sans-serif ',
            fontSize: 13,
            marginTop: "0",
            backgroundColor: "unset !important",
            // !important attribute to override the default background
            // color of white
            color: `${themePalette.palette.common.white}`,
            padding: 0,
          },
        },

        {
          props: { variant: "outlined" },
          style: {
            fontSize: 17,
            "&:hover": {
              backgroundColor: themePalette.palette.common.black,
              color: themePalette.palette.common.white,
              boxShadow:
                "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
              border: `1px solid ${themePalette.palette.common.black}`,
            },
          },
        },

        {
          props: { variant: "logout-option" },
          style: {
            "&:hover": {
              // !important attribute to override the default background
              // color and color text on hover of logout modal options
              backgroundColor: "unset !important",
              color: `${themePalette.palette.logoutButton.logoutBtnHover} !important`,
            },
          },
        },
      ],
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: themePalette.palette.eyeIcon.eyeColor,
        },
      },
    },
  },
});
