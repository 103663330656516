import styled from "styled-components/macro";
import theme from "../../../constants/Theme";

const folderPadding = theme.spacing(3.5);

export const StyledFolder = styled.section`
  font-family: PoppinsMedium, Helvetica, Arial, sans-serif;
  font-size: 15px;
  padding-left: ${folderPadding};
  .tree__file {
    padding-left: ${folderPadding};
  }
`;
