import { Route, Routes as MainRoutes } from "react-router-dom";
import ROUTES_PATH from "../constants/routes";
import Login from "../pages/login-form";
import Dashboard from "../containers/Dashboard";
import { PublicRoute, ProtectedRoute } from "./PublicPrivateRouting";

export default function Routes() {
  return (
    <MainRoutes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<Login />} />
        <Route path={ROUTES_PATH.LOG_IN} element={<Login />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path={ROUTES_PATH.DASHBOARD} element={<Dashboard />} />
      </Route>
    </MainRoutes>
  );
}
