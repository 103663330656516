import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import TablePaginationActions from "../../components/Table/TablePaginationActions";
import services from "../../services";
import { Box, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import CategoryDropdown from "./CategoryDropdown";
import { hideLoader, setWorkoutListData } from "../../modules/actions";
import "./Common.css";

export default function BasicTable() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filterRowsPerPage, setFilterRowsPerPage] = useState(5);
  const [filterPage, setFilterPage] = useState(0);
  const [finalData, setFinalData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [category, setCategory] = useState("");
  const [fData, setFData] = useState([]);
  const [backUpData, setBackUpData] = useState([]);

  const dispatch = useDispatch();
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const workoutListData = useSelector(
    (state) => state.dashboard.workoutListData
  );

  useEffect(() => {
    getAllWorkoutMainData();
  }, []);

  const getAllWorkoutMainData = () => {
    services
      .getWorkoutDataFromDatabase()
      .then((res) => {
        dispatch(
          setWorkoutListData({
            workoutListData: res.response,
            error: null,
          })
        );
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(setWorkoutListData({ workoutListData: [], error: error }));
        dispatch(hideLoader());
      });
  };

  useEffect(() => {
    let data = [];
    let allData = [];

    setLoader(true);
    workoutListData.forEach((listData) => {
      if (listData?.data?.title) {
        listData?.data?.workouts.forEach((workout) => {
          workout?.workoutVideos.forEach((videos) => {
            let obj = {
              category: listData.workoutType,
              subCategory1: listData.data.title,
              subCategory2: "-",
              workoutName: workout.workoutName,
              videoTitle: videos.videoTitle,
              videoId: videos.videoId,
            };
            data.push(obj);
          });
        });
      } else {
        listData?.data?.forEach((elem) => {
          elem?.data?.forEach((ele) => {
            ele?.workouts?.forEach((workoutData) => {
              workoutData?.workoutVideos?.forEach((videos) => {
                let obj = {
                  category: listData.workoutType,
                  subCategory1: elem.title,
                  subCategory2: ele.title,
                  workoutName: workoutData.workoutName,
                  videoTitle: videos.videoTitle,
                  videoId: videos.videoId,
                };
                data.push(obj);
              });
            });
          });
        });
      }
    });
    let mergedlist = [...allData, ...data];

    services
      .getPurchasedVideosList()

      .then(({ response }) => {
        if (response.status == 200) {
          let idList = [...response.data];
          let updatedList = mergedlist.map((obj) => {
            let count = idList.filter((obj2) => obj2 == obj?.videoId);
            return {
              ...obj,
              count: count,
            };
          });
          const sortedData = [...updatedList].sort(
            (a, b) => b.count.length - a.count.length
          );
          setFinalData([...finalData, ...sortedData]);
          setBackUpData([...backUpData, ...sortedData]);

          setLoader(false);
        }
      });
  }, [workoutListData]);

  useEffect(() => {
    // 1st
    if (category == "expert") {
      let filteredData = backUpData.filter((obj) => obj.category == "expert");
      setFData([...filteredData]);
    }

    // 2nd
    if (category == "beginner") {
      let filteredData = backUpData.filter((obj) => obj.category == "beginner");
      setFData([...filteredData]);
    }

    // 3rd
    if (category == "intermediate") {
      let filteredData = backUpData.filter(
        (obj) => obj.category == "intermediate"
      );
      setFData([...filteredData]);
    }

    // 4th
    if (category == "advanced") {
      let filteredData = backUpData.filter((obj) => obj.category == "advanced");
      setFData([...filteredData]);
    }

    // 5th
    if (category == "miscellaneous") {
      let filteredData = backUpData.filter(
        (obj) => obj.category == "miscellaneous"
      );
      setFData([...filteredData]);
    }

    if (category == "all") {
      setFData([...backUpData]);
    }
  }, [category]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChangePage = (event, newPage) => {
    setFilterPage(newPage);
  };

  const handleFilterChangeRowsPerPage = (event) => {
    setFilterRowsPerPage(parseInt(event.target.value, 10));
    setFilterPage(0);
  };

  const styles = {
    loaderStyle: {
      position: "absolute",
      top: "7%",
      right: 0,
      left: 0,
      bottom: 0,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      width: 1,
      zIndex: 999,
    },
  };

  return (
    <>
      <CategoryDropdown change={handleChange} onSelectValue={category} />

      <TableContainer component={Paper}>
        {loader && (
          <Box sx={styles.loaderStyle}>
            <CircularProgress
              style={{ color: "#FFFFFF", width: "100px", height: "100px" }}
            />
          </Box>
        )}

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Video Name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Sub Category 1</TableCell>
              <TableCell align="center">Sub Category 2</TableCell>
              <TableCell align="center">Work Out</TableCell>
              <TableCell align="center">Number of Purchases</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!category ? (
              (rowsPerPage > 0
                ? finalData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : finalData
              ).map((elem, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {elem.videoTitle}
                    </TableCell>
                    <TableCell align="center">{elem.category}</TableCell>
                    <TableCell align="center">{elem.subCategory1}</TableCell>
                    <TableCell align="center">{elem.subCategory2}</TableCell>
                    <TableCell align="center">{elem.workoutName}</TableCell>
                    <TableCell align="center">{elem.count.length}</TableCell>
                  </TableRow>
                );
              })
            ) : fData.length ? (
              (filterRowsPerPage > 0
                ? fData.slice(
                    filterPage * filterRowsPerPage,
                    filterPage * filterRowsPerPage + filterRowsPerPage
                  )
                : fData
              ).map((elem, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {elem.videoTitle}
                    </TableCell>
                    <TableCell align="center">{elem.category}</TableCell>
                    <TableCell align="center">{elem.subCategory1}</TableCell>
                    <TableCell align="center">{elem.subCategory2}</TableCell>
                    <TableCell align="center">{elem.workoutName}</TableCell>
                    <TableCell align="center">{elem.count.length}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className="no-data-cell">
                  <Typography variant="h5" style={{ color: "#6b7089" }}>
                    No Data Available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!category ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={finalData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "workoutVideosList per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={fData.length}
                  rowsPerPage={filterRowsPerPage}
                  page={filterPage}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "workoutVideosList per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleFilterChangePage}
                  onRowsPerPageChange={handleFilterChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
