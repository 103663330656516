import * as React from "react";
import commontext from "../../constants/commontext";
import theme from "../../constants/Theme";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "../../constants/Theme/styles";
import { OnlyMP4Text } from "../../containers/Dashboard/styles";

import { useDispatch, useSelector } from "react-redux";
import { getFileExtension, isEmptyOrNull } from "../../utils/inputvalidation";
import {
  bytesToSize,
  capFirst,
  loadVideo,
  secondsToTime,
} from "../../utils/helper";
import AllIcons from "../../constants/icons/icons";
import { toggleSnackBar } from "../../modules/actions";

export default function UploadVideo(props) {
  const CHARACTER_LIMIT = 30;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [video, setVideo] = React.useState(null);
  const [videoDuration, setVideoDuration] = React.useState("");
  const breadCumpNavData = useSelector(
    state => state.dashboard.breadCumpNavData
  );
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);

  const [videoTitle, setVideoTitle] = React.useState("");
  const [videoDescription, setVideoDescription] = React.useState("");

  const [values, setValues] = React.useState({
    isTitleError: true,
    isDescriptionError: true,
  });
  const dispatch = useDispatch();

  /**
   * Used when any breadcumb value wil update in store
   */
  React.useEffect(() => {
    if (!isEmptyOrNull(breadCumpNavData)) {
      setBreadcrumbs(breadCumpNavData);
    }
  }, [breadCumpNavData]);

  const handleClose = () => {
    setOpen(false);
    setVideoDuration("");
    setVideoTitle("");
    setVideoDescription("");
    setTimeout(() => {
      setVideo(null);
    }, 500);
  };

  const setSelectedVideo = async event => {
    let fileExt = getFileExtension(event.target.value);
    if (fileExt.toLocaleLowerCase() != "mp4") {
      showSnackBar(commontext.onlyMp4Video);
    } else {
      try {
        const file = event.target.files;
        let { type, value } = bytesToSize(file[0].size);
        if (type == "GB" && value > 5) {
          showSnackBar(commontext.videoValidationMsg);
        } else {
          setVideo(file[0]);
          const video = await loadVideo(file[0]);
          setVideoDuration(secondsToTime(video.duration));
        }
      } catch (error) {
        console.log("error ::", error);
      }
    }
  };

  const onVideoDeleteClick = () => {
    setVideo(null);
  };

  const onClickUploadVideo = () => {
    if (checkValidVideo()) {
      setOpen(false);
      let fileName = videoTitle
      let fileNameWithRemovedSpecialChar = fileName.replace(/[&\/\\#,^`+()$~%|.@;!='":*?<>{}]/g, '')
      let fileNameByRemovingSpace = fileNameWithRemovedSpecialChar.replace(/ +/g, '-')
      let fileNameForSingleSpecialChar = fileNameByRemovingSpace.replace(/[-]/g, " ");
      let finalFileName = fileNameForSingleSpecialChar.replace(/ +/g, '-')
      let mp4FileName = finalFileName
      props.onUploadVideo(video, videoDuration, videoTitle, videoDescription, mp4FileName);
      setVideoDuration("");
      setVideoTitle("");
      setVideoDescription("");
      setTimeout(() => {
        setVideo(null);
      }, 500);
    }
  };

  const checkValidVideo = () => {
    let fileExt = getFileExtension(video?.name);
    if (isEmptyOrNull(video)) {
      showSnackBar(commontext.pleaseSelectVideo);
      return false;
    } else if (fileExt.toLocaleLowerCase() != "mp4") {
      showSnackBar(commontext.onlyMp4Video);
      return false;
    } else {
      return true;
    }
  };

  const showSnackBar = message => {
    dispatch(
      toggleSnackBar({
        showSnackBar: true,
        message: message,
      })
    );
  };

  const RenderDisabledInputs = () => {
    if (breadcrumbs?.length) {
      let routeList = [...breadcrumbs];
      let mainWorkout = routeList.find(obj => obj.level_id === 1);
      let mainEnvWorkout = routeList.find(obj => obj.level_id === 2);
      let subEnvWorkout = routeList.find(obj => obj.level_id === 3);
      let finalWorkoutData = routeList.find(obj => obj.level_id === 4);

      return (
        <>
          <TextField
            disabled
            autoFocus
            fullWidth
            label={commontext.videoType}
            variant="standard"
            defaultValue={capFirst(mainWorkout?.title)}
            sx={{ mt: 3, display: "block" }}
            className={classes.uploadModalDisabledFieldStyle}
          />
          {!isEmptyOrNull(mainEnvWorkout) ? (
            <>
              <TextField
                disabled
                autoFocus
                fullWidth
                label={commontext.chooseLevel}
                variant="standard"
                defaultValue={capFirst(mainEnvWorkout?.title)}
                sx={{ mt: 3.5, display: "block" }}
                className={classes.uploadModalDisabledFieldStyle}
              />

              <TextField
                disabled
                autoFocus
                fullWidth
                label={commontext.chooseEnv}
                variant="standard"
                defaultValue={capFirst(subEnvWorkout?.title)}
                sx={{ mt: 3.5, display: "block" }}
                className={classes.uploadModalDisabledFieldStyle}
              />
            </>
          ) : null}
          <TextField
            disabled
            autoFocus
            fullWidth
            label={capFirst(commontext.chooseCategory)}
            variant="standard"
            defaultValue={finalWorkoutData?.title}
            sx={{ mt: 3.5, display: "block" }}
            className={classes.uploadModalDisabledFieldStyle}
          />
        </>
      );
    } else {
      return null;
    }
  };
  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const getDisabledStatus = () => {
    if (videoTitle != "" && videoDescription != "" && !isEmptyOrNull(video))
      return false;
    else return true;
  };

  const classes = useStyles();
  return (
    <Box sx={{ ml: "auto" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button type="submit" sx={{ px: 7 }} onClick={handleClickOpen}>
          {commontext.add}
        </Button>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="upload-video-dialog"
        fullWidth
        maxWidth="md"
      >
        <Box sx={{ px: 3, pt: 3, pb: 5 }}>
          <DialogContent className={classes.uploadVideoContent}>
            <DialogTitle id="upload-video-dialog" sx={{ fontSize: 31 }}>
              <Grid className={classes.dialogCloseBtn}>
                <Typography variant="div">{commontext.uploadVideo}</Typography>
                <IconButton onClick={handleClose}>
                  <AllIcons.CloseIcon
                    sx={{
                      color: theme => theme.palette.common.black,
                      fontSize: 35,
                    }}
                  />
                </IconButton>
              </Grid>
            </DialogTitle>
            <RenderDisabledInputs />

            <TextField
              fullWidth
              label={commontext.videoTitle}
              variant="standard"
              placeholder={commontext.enterVideoAppear}
              value={videoTitle}
              onChange={event => setVideoTitle(event.target.value)}
              sx={{ mt: 3.5, display: "block" }}
              inputProps={{
                maxlength: CHARACTER_LIMIT,
              }}
              className={classes.uploadModalActiveFieldStyle}
              error={values.isTitleError}
              helperText={
                values.isTitleError ? commontext.pleaseEnterVideoTitle : ""
              }
              onFocus={() => {
                handleChangeValue("isTitleError", false);
              }}
              onBlur={() => {
                if (isEmptyOrNull(videoTitle))
                  handleChangeValue("isTitleError", true);
              }}
            />

            <TextField
              fullWidth
              label={commontext.videoDescription}
              variant="standard"
              placeholder={commontext.enterVideoDescriptionApper}
              value={videoDescription}
              onChange={event => setVideoDescription(event.target.value)}
              sx={{ mt: 3.5, display: "block" }}
              inputProps={{
                maxlength: CHARACTER_LIMIT * 2,
              }}
              className={classes.uploadModalActiveFieldStyle}
              error={values.isDescriptionError}
              helperText={
                values.isDescriptionError
                  ? commontext.pleaseEnterVideoDescription
                  : ""
              }
              onFocus={() => {
                handleChangeValue("isDescriptionError", false);
              }}
              onBlur={() => {
                if (isEmptyOrNull(videoDescription))
                  handleChangeValue("isDescriptionError", true);
              }}
            />
            {!isEmptyOrNull(video) ? (
              <Box className={classes.selectedVideoFile} sx={{ mt: "20px" }}>
                <Typography>{commontext.selectedVideo}</Typography>
                {
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box>{video?.name}</Box>
                    <Button onClick={onVideoDeleteClick}>
                      <AllIcons.DeleteOutlineIcon />
                    </Button>
                  </Box>
                }
              </Box>
            ) : null}

            {isEmptyOrNull(video) ? (
              <Box>
                <Button
                  component="label"
                  variant="outlined"
                  sx={{
                    px: 7,
                    mt: 6,
                    mb: 1,
                    border: `1px solid ${theme.palette.common.black}`,
                  }}
                >
                  {commontext.selectVideo}
                  <input
                    type="file"
                    hidden
                    accept="video/mp4"
                    onChange={setSelectedVideo}
                  />
                </Button>
                <OnlyMP4Text>{commontext.onlyMp4Allowed}</OnlyMP4Text>
              </Box>
            ) : (
              <Button
                component="label"
                variant="outlined"
                disabled={getDisabledStatus()}
                sx={{
                  px: 7,
                  mt: 6,
                  backgroundColor: theme =>
                    getDisabledStatus()
                      ? theme.palette.disabledButton.btnBg
                      : theme.palette.common.black,
                  opacity: getDisabledStatus() ? 0.4 : 1,
                  color: `${
                    getDisabledStatus()
                      ? theme.palette.common.black
                      : theme.palette.common.white
                  } !important`,
                  boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.25)",
                }}
                onClick={onClickUploadVideo}
              >
                {commontext.uploadVideo}
              </Button>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}
