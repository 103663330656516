import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
    loading: false,

    showSnackBar: false,
    message: '',
}

const auth = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SHOW_LOADER: {
            return {
                ...state,
                loading: true,
            }
            break;
        }

        case actionTypes.HIDE_LOADER: {
            return {
                ...state,
                loading: false,
            }
            break;
        }

        case actionTypes.TOGGLE_SNACK_BAR: {
            return {
                ...state,
                showSnackBar: action?.payload?.showSnackBar,
                message: action?.payload?.message,
            }
            break;
        }

        case actionTypes.RESET_STORE:
            return initialState;
            break;

        default:
            return state;
    }
}

export default auth;