import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./modules";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from "./routes";
import "./App.css";
import Loader from "./common/loader/loader";
import SimpleSnackbar from "./components/SnackBar";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            icon={false}
          />
          <Loader />
          <SimpleSnackbar />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
