import styled from "@emotion/styled";
import theme from "../../constants/Theme";
import { themePalette } from "../../constants/Theme/colors";

export const EditFieldWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiInput-input": {
      color: themePalette.palette.common.black,
    },

    "& .MuiInput-root:before": {
      borderBottom: `1px solid ${themePalette.palette.editField.borderColor} !important`,
    },

    "& .MuiOutlinedInput-input": {
      color: "unset !important",
      padding: "11px",
    },

    "& .MuiInputLabel-root": {
      fontSize: 16,
      color: themePalette.palette.inputField.inputAfterColor,
    },

    "& .MuiFormLabel-root": {
      color: "unset !important",
    },

    "& .MuiFormLabel-root": {
      color: "unset !important",
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },

    "& .MuiFormControl-root": {
      width: "100%",
    },

    "& .MuiTableCell-root": {
      paddingLeft: "11px",
      backgroundColor: "unset",
    },
  };
});

export const TableWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiIconButton-root:hover": {
      background: "unset",
    },
    "& .MuiTableCell-root": {
      // background: `${themePalette.palette.common.white} !important`,
    },

    "& .MuiFormHelperText-root": {
      marginRight: 0,
      fontSize: 12,
    },
  };
});

export const CenterTextWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiTypography-root": {
      fontSize: 28,
    },
  };
});

export const AddPromoWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiButton-root.Mui-disabled": {
      background: themePalette.palette.button.buttonHoverBg,
      color: themePalette.palette.common.white,
    },
  };
});
