import * as actionTypes from "./actionTypes";

export const resetReduxStore = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_STORE,
        });
    };
};

export const showLoader = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.SHOW_LOADER,
        });
    };
}

export const hideLoader = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.HIDE_LOADER,
        });
    };
}

export const toggleSnackBar = (payload) => {
    return dispatch => {
        dispatch({
            type: actionTypes.TOGGLE_SNACK_BAR,
            payload
        });
    };
}
