import * as actionTypes from "./actionTypes";

export const setWorkoutListData = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_WORKOUT_LIST_DATA,
            payload,
        });
    };
};

export const setBreadCumpNavigationData = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_BREADCUMP_NAVIGATION_DATA,
            payload,
        });
    };
}

export const setSelectedWorkoutData = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_WORKOUT_DATA,
            payload,
        });
    };
}

export const toggleVideoUploadStatus = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.TOGGLE_VIDEO_UPLOAD_STATUS,
            payload,
        });
    };
}

export const updateVideoQueueList = payload => {
    return dispatch => {
        dispatch({
            type: actionTypes.UPDATE_VIDEO_QUEUE_LIST,
            payload,
        });
    };
}

export const storeVideoListAndUpdate = (payload) => {
    return dispatch => {
        dispatch({
            type: actionTypes.STORE_VIDEO_LIST_AND_UPDATE,
            payload,
        });
    };
}