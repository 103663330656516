import { signInWithEmailAndPassword, signOut } from "firebase/auth"
import { collection, getDoc, doc, getDocs, updateDoc, setDoc, query, orderBy, limit, startAfter } from "firebase/firestore"
import commontext from "../constants/commontext"
import { FirebaseAuth, FirestoreDb } from "../firebaseutils/firebase"
import AwsCredentials from "../utils/awsCredentials"
import { isEmptyOrNull } from "../utils/inputvalidation"
import S3 from "aws-s3";
import { getKeyPrefix, getKeyPrefixForm3u8, getKeyPrefixForMp4, getKeyPrefixFormStreamingFolder, } from "../utils/helper"
import _ from "lodash";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client as AWSS3Client, S3 as AWSS3 } from "@aws-sdk/client-s3";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import environmentVariable from "../utils/environmentVariable"
import axios from "axios"

const loginWithEmailPassword = (email, password) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(FirebaseAuth, email, password).then((user) => {
            resolve(getAdminInfoFromDatabase(user.user))
        }).catch((error) => {
            reject({
                success: false,
                isError: true,
                response: error,
            })
        })
    })
}

const getAdminInfoFromDatabase = (user) => {
    return new Promise(async (resolve, reject) => {
        const docRef = doc(FirestoreDb, commontext.usersCollection, user.uid);
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                let userData = docSnap.data();
                let userRole = userData.userRole;
                if (userRole == commontext.adminText1 || userRole == commontext.adminText2) {
                    resolve({
                        success: true,
                        isError: false,
                        response: userData,
                    })
                }
                else {
                    resolve({
                        success: false,
                        isError: false,
                        message: commontext.adminCredentialRequired
                    })
                }
            }
            else {
                resolve({
                    success: false,
                    isError: false,
                    message: commontext.somethingWentWrong
                })
            }
        }).catch((error) => {
            resolve({
                success: false,
                isError: true,
                response: error,
                message: commontext.somethingWentWrong
            })
        })
    })
}

const signOutFromApp = () => {
    return new Promise((resolve, reject) => {
        signOut(FirebaseAuth).then(() => {
            resolve({
                success: true,
                isError: false,
            })
        }).catch((error) => {
            reject({
                success: false,
                isError: true,
                response: error,
            })
        })
    })
}

const getWorkoutDataFromDatabase = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let querySnapshot = await getDocs(collection(FirestoreDb, commontext.workoutCollection));
            let requiredList = querySnapshot.docs.map((obj) => obj.data())
            requiredList = requiredList.filter((obj) => obj.workoutType)
            resolve({
                success: true,
                isError: false,
                response: requiredList,
            })
        } catch (error) {
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const getDataForPerticularWorkout = data => {
    return new Promise(async (resolve, reject) => {
        let routeList = [...data];
        let mainWorkout = routeList.find(obj => obj.level_id === 1);
        let mainEnvWorkout = routeList.find(obj => obj.level_id === 2);
        try {
            const docRef = doc(
                FirestoreDb,
                commontext.workoutCollection,
                mainWorkout?.title
            );
            const docSnap = await getDoc(docRef);
            let docWorkout = docSnap.data();

            if (!isEmptyOrNull(mainEnvWorkout)) {
                let allEnvData = [...docWorkout.data];
                let requiredEnvData = allEnvData.find(
                    obj => obj.title == mainEnvWorkout.title
                );
                if (!isEmptyOrNull(requiredEnvData)) {
                    let subEnvWorkout = routeList.find(obj => obj.level_id === 3);

                    let allSubEnvData = [...requiredEnvData.data]
                    let requiredSubEnvData = allSubEnvData.find(obj => obj.title == subEnvWorkout.title)

                    if (!isEmptyOrNull(requiredSubEnvData)) {
                        let finalWorkoutData = routeList.find(obj => obj.level_id === 4);

                        let allSubEnvWorkoutData = [...requiredSubEnvData.workouts];
                        let requiredSubEnvWorkoutData = allSubEnvWorkoutData.find((obj) => obj.workoutName == finalWorkoutData.title);
                        resolve({
                            success: true,
                            error: false,
                            response: requiredSubEnvWorkoutData
                        })
                    }
                    else {
                        resolve({
                            success: false,
                            error: true,
                            response: null
                        })
                    }
                }
                else {
                    resolve({
                        success: false,
                        error: true,
                        response: null
                    })
                }
            }
            else {
                let finalWorkoutData = routeList.find(obj => obj.level_id === 4);

                let allSubEnvWorkoutData = [...docWorkout.data.workouts];
                let requiredSubEnvWorkoutData = allSubEnvWorkoutData.find((obj) => obj.workoutName == finalWorkoutData.title);
                resolve({
                    success: true,
                    error: false,
                    response: requiredSubEnvWorkoutData
                })
            }

        } catch (error) {
            resolve({
                success: false,
                error: true,
                response: error,
            })
        }
    })
}

const uploadS3FileToAws = (video, fileName, breadcrumbs) => {

    let formattedKeyPrefix = breadcrumbs;
    formattedKeyPrefix = formattedKeyPrefix.map((obj) => {
        return obj.title.replace(/[&/\\]/g, '')
    }).join("/");
    let bucketName = environmentVariable.APP_ENVIRONMENT == "DEV" ? AwsCredentials.phalanxS3Bucket_dev : AwsCredentials.phalanxS3Bucket_stag
    const options = {
        bucket: bucketName,
        region: AwsCredentials.region,
        accessKey: AwsCredentials.accessKeyId,
        secretKey: AwsCredentials.secretAccessKey,
        successActionStatus: 201,
        keyPrefix: AwsCredentials.bucketName + "/" + formattedKeyPrefix,
    };
    const S3Client = new S3({
        bucketName: options.bucket,
        dirName: options.keyPrefix /* optional */,
        region: options.region,
        accessKeyId: options.accessKey,
        secretAccessKey: options.secretKey,
    });

    return new Promise((resolve, reject) => {
        S3Client.uploadFile(video, fileName)
            .then(response => {
                resolve({
                    success: true,
                    response: response,
                });
            })
            .catch(error => {
                reject({
                    success: false,
                    isError: true,
                    response: error,
                });
            });
    });
};

const getLisOfMainWorkoutWithSubmittedVideo = (
    breadcrumbs,
    workoutListData,
    response,
    fileName,
    videoDuration,
    videoTitle,
    videoDescription,
    requiedObj
) => {
    return new Promise((resolve, reject) => {
        let routeList = [...breadcrumbs];
        let mainWorkout = routeList.find(obj => obj.level_id === 1);
        let mainEnvWorkout = routeList.find(obj => obj.level_id === 2);
        let workoutListDataUpdate = [...workoutListData];
        let mainWorkoutData = workoutListDataUpdate.find(
            obj => obj.workoutType == mainWorkout.title
        );
        if (!isEmptyOrNull(mainEnvWorkout)) {
            let subEnvWorkout = routeList.find(obj => obj.level_id === 3);
            let finalWorkoutData = routeList.find(obj => obj.level_id === 4);
            let mainEnvWorkoutData = mainWorkoutData?.data?.map(obj => {
                if (obj.title == mainEnvWorkout.title) {
                    delete obj.parentNode;
                    return {
                        ...obj,
                        data: obj?.data?.map(obj2 => {
                            if (obj2.title == subEnvWorkout.title) {
                                delete obj2.parentNode;
                                return {
                                    ...obj2,
                                    workouts: obj2?.workouts?.map(obj3 => {
                                        if (obj3.workoutName == finalWorkoutData.title) {
                                            let workoutVideosList = [...obj3.workoutVideos];
                                            workoutVideosList.push({
                                                ...requiedObj,
                                            });
                                            delete obj3.parentNode;
                                            return {
                                                ...obj3,
                                                workoutVideos: workoutVideosList.map(obj4 => {
                                                    delete obj4.parentNode;
                                                    return obj4;
                                                }),
                                            };
                                        } else {
                                            delete obj3.parentNode;
                                            let workoutVideosList = obj3.workoutVideos;
                                            return {
                                                ...obj3,
                                                workoutVideos: workoutVideosList.map(obj4 => {
                                                    delete obj4.parentNode;
                                                    return obj4;
                                                }),
                                            };
                                        }
                                    }),
                                };
                            } else {
                                delete obj2.parentNode;
                                return {
                                    ...obj2,
                                    workouts: obj2?.workouts?.map(obj3 => {
                                        delete obj3.parentNode;
                                        return {
                                            ...obj3,
                                            workoutVideos: obj3?.workoutVideos?.map(obj4 => {
                                                delete obj4.parentNode;
                                                return obj4;
                                            }),
                                        };
                                    }),
                                };
                            }
                        }),
                    };
                } else {
                    delete obj.parentNode;
                    return {
                        ...obj,
                        data: obj.data.map(obj2 => {
                            delete obj2.parentNode;
                            return {
                                ...obj2,
                                workouts: obj2?.workouts?.map(obj3 => {
                                    delete obj3.parentNode;
                                    return {
                                        ...obj3,
                                        workoutVideos: obj3?.workoutVideos?.map(obj4 => {
                                            delete obj4.parentNode;
                                            return obj4;
                                        }),
                                    };
                                }),
                            };
                        }),
                    };
                }
            });
            mainWorkoutData = {
                ...mainWorkoutData,
                data: mainEnvWorkoutData,
            };
        } else {
            let finalWorkoutData = routeList.find(obj => obj.level_id === 4);
            let allSubEnvWorkoutData = [...mainWorkoutData.data.workouts];
            let subWorkoutUpdatedList = [];
            allSubEnvWorkoutData.map(obj5 => {
                if (obj5.workoutName == finalWorkoutData.title) {
                    let workoutVideosList = [...obj5.workoutVideos];
                    workoutVideosList.push({
                        ...requiedObj,
                    });

                    subWorkoutUpdatedList.push({
                        ...obj5,
                        workoutVideos: workoutVideosList,
                    });
                } else {
                    delete obj5.parentNode;
                    let workoutVideosList = obj5.workoutVideos;

                    subWorkoutUpdatedList.push({
                        ...obj5,
                        workoutVideos: workoutVideosList,
                    });
                }
            });
            let { data, ...rest2 } = mainWorkoutData;
            let { workouts, ...rest3 } = data;
            mainWorkoutData = {
                ...rest2,
                data: {
                    ...rest3,
                    workouts: subWorkoutUpdatedList,
                },
            };
        }
        mainWorkoutData.parentNode = {};
        resolve(mainWorkoutData);
    });
};

const storeUploadedVideoUrlInFirestore = (data, dataNeedToStore) => {
    return new Promise(async (resolve, reject) => {
        let routeList = [...data];
        let mainWorkout = routeList.find(obj => obj.level_id === 1);
        try {
            const docRef = doc(FirestoreDb, commontext.workoutCollection, mainWorkout.title);
            updateDoc(docRef, JSON.parse(JSON.stringify(dataNeedToStore))).then(() => {
                resolve({
                    success: true,
                })
            }).catch((error) => {
                reject({
                    success: false,
                    isError: true,
                    response: error
                })
            })
        } catch (error) {
            reject({
                success: false,
                isError: true,
                response: error
            })
        }
    })
}

const uploadVideoToS3BucketWithProgress = (
    video,
    fileName,
    breadcrumbs,
    progressCallBack
) => {
    let formattedKeyPrefix = breadcrumbs;
    formattedKeyPrefix = formattedKeyPrefix
        .map(obj => obj.title.replace(/[&/\\]/g, ""))
        .join("/");

    let bucketName = environmentVariable.APP_ENVIRONMENT == "DEV" ? AwsCredentials.phalanxS3Bucket_dev : AwsCredentials.phalanxS3Bucket_stag
    const options = {
        bucket: bucketName,
        region: AwsCredentials.region,
        accessKey: AwsCredentials.accessKeyId,
        secretKey: AwsCredentials.secretAccessKey,
        successActionStatus: 201,
        keyPrefix: AwsCredentials.bucketName + "/" + formattedKeyPrefix,
    };
    const uploadParams = {
        Bucket: options.bucket,
        Key: getKeyPrefix(formattedKeyPrefix, fileName), //`${options.keyPrefix}/${fileName}.mp4`,
        Body: video,
        ACL: "public-read",
        BucketKeyEnabled: true,
    };
    const awsParam = {
        requestHandler: new XhrHttpHandler({}),
        credentials: {
            accessKeyId: options.accessKey,
            secretAccessKey: options.secretKey,
        },
        region: options.region,
    };
    const config = {
        client: new AWSS3(awsParam),
        leavePartsOnError: false,
        params: uploadParams,
    };

    return new Promise((resolve, reject) => {
        try {
            const paralellUploads3 = new Upload(config);
            paralellUploads3.on('httpUploadProgress', (progress) => {
                const percent = Math.round((progress.loaded / progress.total) * 100);
                progressCallBack({
                    isProgress: true,
                    isSuccess: true,
                    response: {
                        ...progress,
                        key: progress.Key,
                    },
                })
            });
            paralellUploads3.done().then((response) => {
                resolve({
                    success: true,
                    response: {
                        ...response,
                        key: response.Key,
                        location: response.Location,
                    },
                })
            }).catch((error) => {
                reject({
                    success: false,
                    isError: true,
                    response: error,
                })
            })
        } catch (error) {
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const getPurchasedVideosList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios({
                method: 'GET',
                url: environmentVariable.APP_ENVIRONMENT == "DEV" ?
                    'https://us-central1-phalanx-develop.cloudfunctions.net/getPurchasedCollectionList' :
                    'https://us-central1-phalanx-staging.cloudfunctions.net/getPurchasedCollectionList'
                ,
                headers: { "Access-Control-Allow-Origin": "*" }
            }).then((response) => {
                resolve({
                    success: true,
                    response: response,
                    isError: false,
                })
            }).catch((error) => {
                console.error('error===> ', error);
                reject({
                    success: false,
                    isError: true,
                    response: error,
                })
            });
        } catch (error) {
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const deleteVideoFromAws = async (
    fileName,
    breadcrumbs,
) => {
    let formattedKeyPrefix = breadcrumbs;
    // formattedKeyPrefix = formattedKeyPrefix.map(obj => obj.title.replace(/[&/\\]/g, "")).join("/");

    let keyPrefix = getKeyPrefixForMp4(formattedKeyPrefix, fileName);

    let keyPrefixform3u8 = getKeyPrefixForm3u8(formattedKeyPrefix, fileName);

    let keyPrefixformStreamingFolder = getKeyPrefixFormStreamingFolder(formattedKeyPrefix, fileName);

    let bucketName = environmentVariable.APP_ENVIRONMENT == "DEV" ? AwsCredentials.phalanxS3Bucket_dev : AwsCredentials.phalanxS3Bucket_stag
    const options = {
        bucket: bucketName,
        region: AwsCredentials.region,
        accessKey: AwsCredentials.accessKeyId,
        secretKey: AwsCredentials.secretAccessKey,
        successActionStatus: 201,
        keyPrefix: AwsCredentials.bucketName + "/" + formattedKeyPrefix,
        mp4Key: keyPrefix,
        m3u8Key: keyPrefixform3u8,
        streamFolderKey: keyPrefixformStreamingFolder
    };
    const awsParam = {
        credentials: {
            accessKeyId: options.accessKey,
            secretAccessKey: options.secretKey,
        },
        region: options.region,
    };
    const s3 = new AWSS3(awsParam)
    let deleteObjList = {
        Bucket: options.bucket,
        Delete: {
            Objects: [
                {
                    Key: options.mp4Key
                },
                {
                    Key: options.m3u8Key,
                },
                {
                    Key: options.streamFolderKey,
                }
            ]
        }
    }
    const listedObjects = await s3.listObjectsV2({
        Bucket: options.bucket,
        Prefix: options.streamFolderKey
    })

    listedObjects.Contents.forEach(({ Key }) => {
        deleteObjList.Delete.Objects.push({ Key });
    });

    return new Promise((resolve, reject) => {
        s3.deleteObjects(deleteObjList).then((response) => {
            resolve({
                success: true,
                response: response,
                isError: false,
            })
        }).catch((error) => {
            console.log("Error:", error);
            reject({
                success: false,
                isError: true,
                response: error,
            })
        })
    })
}

const getAllListOfPromoTable = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let querySnapshot = await getDocs(collection(FirestoreDb, commontext.promoCodeCollection));
            let requiredList = querySnapshot.docs.map((obj) => obj.data())
            // requiredList = requiredList.filter((obj) => obj.workoutType)
            resolve({
                success: true,
                isError: false,
                response: requiredList,
            })
        } catch (error) {
            console.log("Error:", error);
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const setPromoToStoreList = (promoData, docId) => {
    return new Promise(async (resolve, reject) => {
        try {
            await setDoc(doc(FirestoreDb, commontext.promoCodeCollection, docId), {
                ...promoData
            });
            resolve({
                success: true,
                isError: false,
            })
        } catch (error) {
            console.log("Error:", error);
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const getPromoListForFirstPage = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const first = query(collection(FirestoreDb, commontext.promoCodeCollection)
                , orderBy("date_created", "desc"),
                limit(10));
            const documentSnapshots = await getDocs(first);
            let requiredList = documentSnapshots.docs.map((obj) => obj.data())

            resolve({
                success: true,
                isError: false,
                response: requiredList,
            })

        } catch (error) {
            console.log("Error:", error);
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const getPromoListForPagination = async (lastVisible) => {
    return new Promise(async (resolve, reject) => {
        try {
            const next = query(collection(FirestoreDb, commontext.promoCodeCollection),
                orderBy("date_created", "desc"),
                startAfter(lastVisible),
                limit(10));
            const documentSnapshots = await getDocs(next);
            let requiredList = documentSnapshots.docs.map((obj) => obj.data())

            resolve({
                success: true,
                isError: false,
                response: requiredList,
            })
        } catch (error) {
            console.log("Error:", error);
            reject({
                success: false,
                isError: true,
                response: error,
            })
        }
    })
}

const services = {
    signOutFromApp,
    uploadS3FileToAws,
    loginWithEmailPassword,
    getAdminInfoFromDatabase,
    getWorkoutDataFromDatabase,
    getDataForPerticularWorkout,
    storeUploadedVideoUrlInFirestore,
    getLisOfMainWorkoutWithSubmittedVideo,
    uploadVideoToS3BucketWithProgress,
    getPurchasedVideosList,
    deleteVideoFromAws,
    getAllListOfPromoTable,
    setPromoToStoreList,
    getPromoListForFirstPage,
    getPromoListForPagination,
};

export default services;
