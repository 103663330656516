import React, { useState, useEffect } from "react";
import {
  ActionsWrapper,
  Collapse,
  StyledName,
  VerticalLine,
} from "../Tree.style";
import { StyledFolder } from "./TreeFolder.style";

import { useTreeContext } from "../state/TreeContext";
import { capFirst } from "../../../utils/helper";
import AllIcons from "../../../constants/icons/icons";
import { FOLDER } from "../state/constants";
import { PlaceholderInput } from "../TreePlaceholderInput";

const FolderName = ({ isOpen, name, handleClick }) => (
  <StyledName onClick={handleClick}>
    {<AllIcons.FolderOutlinedIcon />}
    &nbsp;&nbsp;{name}
  </StyledName>
);

const Folder = ({
  id,
  name,
  children,
  node,
  folderActions,
  onAddButtonClick,
  onEditButtonClick,
  showEditIcon,
}) => {
  const { dispatch, isImparative, onNodeClick } = useTreeContext();
  const [isEditing, setEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [childs, setChilds] = useState([]);

  useEffect(() => {
    setChilds([children]);
  }, [children]);

  useEffect(() => {
    if (node && node?.needToOpen == true) {
      setIsOpen(true)
    }
  }, [node?.needToOpen])

  const commitFolderCreation = name => {
    dispatch({ type: FOLDER.CREATE, payload: { id, name } });
  };
  const commitFolderEdit = name => {
    dispatch({ type: FOLDER.EDIT, payload: { id, name } });
    setEditing(false);
  };

  const handleFolderCreation = event => {
    onAddButtonClick(node);
  };

  const handleCancel = () => {
  };

  const handleNodeClick = React.useCallback(
    event => {
      event.stopPropagation();
      onNodeClick({ node });
    },
    [node]
  );

  const handleFolderEdit = () => {
    onEditButtonClick(node)
  }

  return (
    <StyledFolder id={id} onClick={handleNodeClick} className="tree__folder">
      <VerticalLine>
        <ActionsWrapper>
          {isEditing ? (
            <PlaceholderInput
              type="folder"
              style={{ paddingLeft: 0 }}
              defaultValue={name}
              onClick={handleCancel}
              onSubmit={commitFolderEdit}
            />
          ) : (
            <FolderName
              name={capFirst(name)}
              isOpen={isOpen}
              handleClick={() => setIsOpen(!isOpen)}
            />
          )}
          {
            <div className="actions">
              {showEditIcon ? <AllIcons.ModeEditOutlineOutlinedIcon
                onClick={handleFolderEdit}
              /> : null}
              {folderActions ? <AllIcons.CreateNewFolderOutlinedIcon
                onClick={handleFolderCreation}
              /> : null}
            </div>
          }
        </ActionsWrapper>

        <Collapse className="tree__folder--collapsible" isOpen={isOpen}>
          {childs}
        </Collapse>
      </VerticalLine>
    </StyledFolder>
  );
};

export { Folder, FolderName };
