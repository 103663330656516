import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";
import _ from "lodash";

const initialState = {
    workoutListData: [],
    error: null,
    breadCumpNavData: [],
    selectedWorkoutData: null,

    isVideoUploading: false,

    queueVideoUploadingList: [],
    workoutVideosList: [],
}

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_WORKOUT_LIST_DATA: {
            return updateObject(state, action.payload);
            break;
        }

        case actionTypes.SET_BREADCUMP_NAVIGATION_DATA: {
            return updateObject(state, action.payload);
            break;
        }

        case actionTypes.SET_SELECTED_WORKOUT_DATA: {
            return updateObject(state, action.payload);
            break;
        }

        case actionTypes.TOGGLE_VIDEO_UPLOAD_STATUS: {
            return updateObject(state, action.payload);
            break;
        }

        case actionTypes.UPDATE_VIDEO_QUEUE_LIST: {
            return {
                ...state,
                queueVideoUploadingList: action.payload.queueVideoUploadingList
            }
            break;
        }

        case actionTypes.STORE_VIDEO_LIST_AND_UPDATE: {
            let mergedList = [...state.workoutVideosList, ...action?.payload?.workoutVideosList || []] || [];
            let uniqList = _.uniqBy(mergedList, 'videoId')

            let uniqListUpdated = uniqList.map((obj1) => {
                let obj2 = [...action?.payload?.workoutVideosList].find((obj2) => obj1.videoId == obj2.videoId);
                if (obj2) {
                    return obj2
                }
                else {
                    return obj1
                }
            })
            return {
                ...state,
                workoutVideosList: action?.payload?.isWorkoutChange ? [...action?.payload?.workoutVideosList] : [...uniqListUpdated]
            }
            break;
        }

        case actionTypes.RESET_STORE:
            return initialState;
            break;

        default:
            return state;
    }
}

export default dashboard;