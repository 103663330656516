const simpletext = {
  isLogin: "IS_LOGIN",

  emailRequired: "Email is required",
  validEmailRequired: "Valid email is required",
  passwordRequired: "Password is required",
  validPasswordRequired:
    "Password must be 8-15 characters long including special characters and numbers",
  enterEmailAddress: "Enter your email address",
  enterPassword: "Enter your Password",
  userNotFound: "User not found",
  passwordCorrect: "Password is not correct",
  somethingWentWrong: "Something went wrong",
  loginSuccess: "Logged in successfully.",
  adminCredentialRequired: "Admin Credentials are required.",
  logoutSuccess: "Logged out successfully.",

  videoAppName: "Video name in the app",
  fileNameAws: "File name on AWS S3",
  areYouSureLogOut: "Are you sure you want to log out?",
  doNotLeavePage:
    "Do not leave this page or close the browser window until the video uploading is complete",
  enterVideoAppear: "Enter a title that appears in the app",
  pleaseSelectVideo: "Please select video",
  onlyMp4Video: "Only mp4 videos are allowed.",
  videoSuccessAWS: "Video successfully uploaded.",
  videoStoredSuccessfully: "Video infomration stored successfully.",
  videoNameinApp: "Video name in the app",
  fileNameOfAwsS3: "File name on AWS S3",
  uploadStatus: "Upload Status",
  action: "Action",

  adminText1: "admin",
  adminText2: "Admin",
  login: "Login",
  logout: "Logout",
  add: "Add",
  no: "No",
  yes: "Yes",
  sure: "Sure",
  submit: "Submit",
  uploadVideo: "Upload Video",
  videoType: "Video Type",
  chooseLevel: "Choose Level",
  chooseEnv: "Choose Environment/Equipment",
  chooseCategory: "Choose Category",
  videoTitle: "Video Title",
  selectVideoFile: "Select Video File",
  selectedVideo: "Selected Video",
  uploadInProgress: "Upload in Progress",
  uploading: "Uploading",
  complete: "Complete",
  signIn: "Sign In",
  email: "Email",
  password: "Password",
  cancel: "Cancel",

  uploadTitle: "Upload Video",
  selectVideo: "Select Video File",
  videoDescription: "Video Description",
  enterVideoDescriptionApper: "Enter a description that appears in the app",

  uploadProgressTitle: "Upload in Progress",
  uploadProgressSubtitle:
    "Do not leave this page or close the browser window until the video uploading is complete",
  areYouSureCloseOrReloadApp:
    "Are you sure you want leave this page or close the window?",
  onlyMp4Allowed: "Only .mp4 files allowed!",
  selectCategoryText: "Select a category from the left panel!",
  emptyWorkoutText: "No videos found. Click on Add button to add new video.",
  workoutAddedsuccess: "New workout added successfully.",

  addWorkout: "Add Workout",
  addNewWorkout: "Add New Workout",
  workoutTitle: "Workout Title",
  workoutDescription: "Workout Description",
  newWorkoutPlaceholder: "Enter name of the new workout type",
  newWorkoutDescription: "Enter description of new workout",

  pleaseEnterVideoTitle: "Please enter video title",
  pleaseEnterVideoDescription: "Please enter video description",
  pleaseEnterWorkoutTitle: "Please enter workout title",
  pleaseEnterWorkoutDescription: "Please enter workout description",

  newLevelPlaceholder: "Enter level name",
  pleaseEnterLevel: "Please enter level name",
  newEnvironmentPlaceholder: "Enter environment/equipment name",
  pleaseEnterEnvironment: "Please enter environment/equipment name",
  addNewLevel: "Add New Level",
  addNewEnvrionment: "Add New Environment/Equipment",
  levelName: "Level Name",
  environmentName: "Environment/Equipment Name",

  editWorkout: "Edit Workout",
  editLevel: "Edit Level",
  editEnvrionment: "Edit Environment/Equipment",

  update: "Update",
  videoValidationMsg: "Video size should be less than 5GB",
  maxfileSize: "Max. file size 5GB",

  deleteDialogTitle: "Delete Video",
  areYouSureDeleteVideo: "Are you sure you want to delete the workout video",
  deleteDialogSubText1:
    "Once you delete the video, app users will not be able to see this video and this action cannot be undone.",
  deleteDialogSubText2:
    "If this video is already purchased by any user, they can still access it.",
  yesDeleteVideo: "Yes, Delete",
  noDeleteVideo: "No, Cancel",
  videoDeletedSuccessfully: "Video Deleted Successfully.",

  addPromo: "Add Promo",
  promoOffDialogTitle: "Turn Off Promo",
  promoOnDialogTitle: "Turn On Promo",
  areYouSurePromoText1: "This will turn off the promo",
  areYouSurePromoText2:
    "and users will no longer be able to use this discount promo code",
  promoEnableText: "Are you sure want to turn on the promo?",
  promoEditText: "Edit",

  promoCodeAddedSuccessfully: "Promo code added successfully",
  promoCodeUpdatedSuccessfully: "Promo code updated successfully",
  promoCodeTurnedOnSuccessfully: "Promo code turned on successfully",
  promoCodeTurnedOffSuccessfully: "Promo code turned off successfully",

  noPromoText:
    "No Promo Codes found. Click on Add Promo Button to add new promo.",
  promoCodePlaceholder: "Enter 5 digit code",
  discountFieldPlaceholder: "Enter discount",

  discountHeading: "Discount Type",
  promoCodeHeading: "Code",
  applicableHeading: "Applicable On",
  startDateHeading: "Start On",
  endDateHeading: "End On",
  statusHeading: "Status",

  scheduledStatus: "Scheduled",
  finishedStatus: "Finished",
  liveStatus: "Live",

  promoCodeValidation: "Promo Code is required",
  promoCodeLength: "Code must contain 5 characters",
  promoLetterNumValidation: "Only Letters/Numbers allowed",
  uniquePromoCode: "Promo Code must be unique",
  discountValidation: "Discount Value required",
  invalidDiscount: "Discount must be between 1-100",
  applicableOptionsValidation: "Select one or more options",
  endDateValidation: "End date should be greater than start date",
  turnedOffPromoText: "Turned Off",

  liveText: "Live",
  turnedOff: "Turned Off",
};

const collectionNames = {
  workoutCollection: "Workout_collection",
  usersCollection: "users",
  purchasedVideoCollection: "Purchased_videos",
  promoCodeCollection: "Promo_Codes",
};

const commontext = {
  ...simpletext,
  ...collectionNames,
};

export default commontext;
