import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { themePalette } from "../../constants/Theme/colors";
import { toggleSnackBar } from "../../modules/actions";
import MuiAlert from "@mui/material/Alert";
import AllIcons from "../../constants/icons/icons";

export default function SimpleSnackbar() {
  const showSnackBar = useSelector(state => state.app.showSnackBar);
  const message = useSelector(state => state.app.message);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (showSnackBar) {
      setTimeout(() => {
        handleClose();
      }, 5000);
    }
  }, [showSnackBar]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(
      toggleSnackBar({
        showSnackBar: false,
        message: "",
      })
    );
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ mt: 0.5 }}
      >
        <AllIcons.CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={showSnackBar}
      autoHideDuration={5000}
      onClose={handleClose}
      message={message}
      sx={{
        backgroundColor: themePalette.palette.common.white,
        color: themePalette.palette.common.black,
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        icon={false}
        onClose={handleClose}
        action={action}
        sx={{
          width: 1,
          backgroundColor: themePalette.palette.common.white,
          color: themePalette.palette.common.black,
          fontSize: 20,
        }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
