import { updateObject } from "../../../utils/updateObject";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
    error: null,
    promoCodeList: [],
}

const promoCode = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_PROMO_CODE: {
            return updateObject(state, action.payload);
            break;
        }
        case actionTypes.RESET_STORE:
            return initialState;
            break;

        default:
            return state;
    }
}

export default promoCode;