import styled from "styled-components/macro";
import theme from "../../constants/Theme";
import { themePalette } from "../../constants/Theme/colors";

export const StyledTree = styled.div`
  line-height: 1.75;
  z-index: 1;
  padding-top: ${theme.spacing(1.25)};
  padding-bottom: ${theme.spacing(1.25)};

  .wrapper {
    position: relative;
  }

  .tree__input {
    padding: ${theme.spacing(0.75)};
    width: auto;
  }

  .addFolder {
    position: absolute;
    top: 4px;
    right: 25px;
  }

  .cancelAction {
    position: absolute;
    top: 4px;
    right: 5px;
  }
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;

  .actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    padding-right: ${theme.spacing(1.25)};

    > svg {
      cursor: pointer;
      margin-left: ${theme.spacing(1.25)};
      transform: scale(1);
      transition: 0.2s;

      :hover {
        transform: scale(1.1);
      }
    }
  }

  &:hover .actions {
    opacity: 1;
    pointer-events: all;
    transition: 0.2s;
  }
`;

export const StyledName = styled.div`
  background-color: ${themePalette.palette.common.white};
  display: flex;
  cursor: pointer;
`;

export const Collapse = styled.div`
  height: max-content;
  max-height: ${p => (p.isOpen ? "max - content" : "0px")};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

export const VerticalLine = styled.section`
  position: relative;
  z-index: 1;
  :before {
    content: "";
    display: block;
    position: absolute;
    top: 0; /* just to hide 1px peek */
    left: 8px;
    width: 0;
    height: 96%;
    border: 1px solid ${themePalette.palette.treeStructure.verticalLine};
    z-index: -1;
    opacity: 0.6;
  }
`;
