import * as React from "react";
import commontext from "../../constants/commontext";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import AllIcons from "../../constants/icons/icons";

export default function UploadProgressNotification() {

  return (
    <Stack sx={{ mt: 2 }}>
      <Box
        sx={{
          backgroundColor: theme =>
            theme.palette.uploadNotification.notificationBg,
          color: theme =>
            theme.palette.uploadNotification.notificationTextColor,
          px: 2,
          py: "15px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AllIcons.WarningIcon sx={{ fontSize: 35 }} />
          </Box>
          <Box sx={{ pl: 2 }}>
            <Typography variant="uploadTitle">
              {commontext.uploadProgressTitle}
            </Typography>
            <Typography variant="uploadSubtitle" display="block">
              {commontext.uploadProgressSubtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
