import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    // transforms: [
    //     encryptTransform({
    //         secretKey: process.env.NODE_ENV,
    //     }),
    // ],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(thunk);

const store = createStore(pReducer, composeEnhancers(middleware));

const persistor = persistStore(store);

export { persistor, store };
