import styled from "@emotion/styled";
import theme from "../../constants/Theme";
import { themePalette } from "../../constants/Theme/colors";

export const PageWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: `${theme.palette.mainContainer.greyBackground}`,
    color: `${theme.palette.textStyle.textColor}`,
    minHeight: "100vh",
  };
});

export const VideoUrl = styled.div(({ theme }) => {
  return {
    display: "block",
    width: "290px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    [theme.breakpoints.down("lg")]: {
      width: "170px",
    },

    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
  };
});

export const OnlyMP4Text = styled.div(({ theme }) => {
  return {
    fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    display: "block",
    marginLeft: theme.spacing(0.75),
    color: themePalette.palette.videoFileMsg.msgColor,
  };
});

export const TabWrapper = styled.div(({ theme }) => {
  return {
    "& .MuiTab-root": {
      fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
      paddingBottom: 0,
      color: themePalette.palette.common.white,
    },

    "& .Mui-selected": {
      color: `${themePalette.palette.common.white} !important`,
    },

    "& .MuiTabs-indicator": {
      backgroundColor: themePalette.palette.common.white,
    },
  };
});
