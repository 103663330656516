import * as React from "react";
import theme from "../../constants/Theme";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import commontext from "../../constants/commontext";
import { ActionButtonWrapper, CancelButtonWrapper } from "./style";

const DeleteVideo = props => {
  const { open, close, rowData, onClickDelete } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="video-delete-dialog"
      fullWidth
      maxWidth="sm"
      onBackdropClick="false"
    >
      <DialogTitle id="delete-dialog">
        {commontext.deleteDialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: 1 }}>
          {commontext.areYouSureDeleteVideo + " '" + rowData.videoTitle + "' ?"}
          <br />
          <br />
          {commontext.deleteDialogSubText1}
          <br />
          <br />
          {commontext.deleteDialogSubText2}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ mb: 1 }}>
        <CancelButtonWrapper>
          <Button
            autoFocus
            sx={{
              mt: 0,
              fontSize: 15,
              background: theme => theme.palette.common.black,
              color: theme => theme.palette.common.white,
              p: "8px 16px",
            }}
            variant="delete-option"
            onClick={close}
          >
            {commontext.noDeleteVideo}
          </Button>
        </CancelButtonWrapper>

        <ActionButtonWrapper>
          <Button
            autoFocus
            sx={{ mt: 0, fontSize: 15, mr: 1, p: "8px 16px" }}
            variant="delete-option"
            onClick={() => onClickDelete(rowData)}
          >
            {commontext.yesDeleteVideo}
          </Button>
        </ActionButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteVideo;
