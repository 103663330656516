import React, { useEffect } from "react";
import {
  TableCell,
  FormControl,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { EditFieldWrapper } from "../style";
import { isEmptyOrNull } from "../../../utils/inputvalidation";
import { VideoUrl } from "../../../containers/Dashboard/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  arrow: {
    transform: "translate(10px, 0px) !important",
  },
}));

const DropdownTableCell = ({
  row,
  index,
  name,
  onDropdownChange,
  selectValidate,
}) => {
  const { isEditMode } = row;
  const [selectedOption, setSelectedOption] = React.useState([row[name]]);
  const applicableOptions = [
    "Pack of 1",
    "Pack of 2",
    "Pack of 3",
    "Pack of 4",
    "Pack of 5",
    "Pack of 6",
    "Pack of 7",
    "Pack of 8",
    "Pack of 9",
    "Pack of 10",
  ];

  useEffect(() => {
    if (!isEmptyOrNull(row?.applicableOn)) {
      let values = row?.applicableOn?.split(",");
      setSelectedOption(values);
    } else {
      setSelectedOption([]);
    }
  }, [row]);

  const handleChange = event => {
    let {
      target: { value, name },
    } = event;
    value = value.filter(obj => obj != "" && obj != null);
    setSelectedOption(typeof value === "string" ? value.split(", ") : value);
    onDropdownChange(value, name, row, index);
  };
  let classes = useStyles();

  return (
    <>
      <TableCell component="th" scope="row">
        {isEditMode ? (
          <EditFieldWrapper>
            <FormControl sx={{ m: 1, width: 300 }} size="small">
              <Select
                multiple
                name={name}
                displayEmpty
                value={selectedOption}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  selected = selected.filter(obj => obj != "" && obj != null);
                  if (selected.length === 0) {
                    return <>Select Options</>;
                  }
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map(value => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  );
                }}
              >
                <MenuItem disabled value="">
                  Select Options
                </MenuItem>
                {applicableOptions.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mx: 0 }}>
                {selectValidate !== "" ? selectValidate : ""}
              </FormHelperText>
            </FormControl>
          </EditFieldWrapper>
        ) : (
          <Tooltip
            title={row[name]}
            arrow
            placement="bottom-start"
            classes={{ arrow: classes.arrow }}
          >
            <VideoUrl>{row[name]}</VideoUrl>
          </Tooltip>
        )}
      </TableCell>
    </>
  );
};

export default DropdownTableCell;
