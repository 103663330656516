import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import dashboard from "./dashboard/dashboardReducer";
import app from "./app/appReducer";
import promoCode from "./promocode/promocodeReducer";

const rootReducer = combineReducers({
    auth,
    dashboard,
    app,
    promoCode,
});


export default rootReducer;
