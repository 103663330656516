export const SET_AUTH_DATA = "SET_AUTH_DATA";

export const SET_WORKOUT_LIST_DATA = 'SET_WORKOUT_LIST_DATA';
export const SET_BREADCUMP_NAVIGATION_DATA = 'SET_BREADCUMP_NAVIGATION_DATA';
export const SET_SELECTED_WORKOUT_DATA = 'SET_SELECTED_WORKOUT_DATA';

export const RESET_STORE = "RESET_STORE"

export const SHOW_LOADER = "SHOW_LOADER"
export const HIDE_LOADER = "HIDE_LOADER"

export const TOGGLE_VIDEO_UPLOAD_STATUS = "TOGGLE_VIDEO_UPLOAD_STATUS";

export const UPDATE_VIDEO_QUEUE_LIST = 'UPDATE_VIDEO_QUEUE_LIST'

export const STORE_VIDEO_LIST_AND_UPDATE = "STORE_VIDEO_LIST_AND_UPDATE";

export const TOGGLE_SNACK_BAR = 'TOGGLE_SNACK_BAR'

export const SET_ALL_PROMO_CODE = "SET_ALL_PROMO_CODE";