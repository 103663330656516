const AwsCredentials = {
  bucketName: "workoutvideo",
  bucketForChallengeName: "workoutchallenge",
  bucketRewardBadges: "rewardbadges",
  accessKeyId: "AKIA5YIGERJJJ7STV4OL",
  secretAccessKey: "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",
  region: "us-east-2",
  phalanxS3Bucket_dev: "phalanx-development",
  phalanxS3Bucket_stag: "phalanx-staging",
  phalanxS3Bucket: "phalanx-production",
};

export default AwsCredentials;
