import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Breadcrumbs,
  Typography,
  Stack,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import { PageWrapper, TabWrapper, VideoUrl } from "./styles";
import NewWorkout from "../../components/NewWorkout";

import services from "../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  resetReduxStore,
  setWorkoutListData,
  showLoader,
  toggleSnackBar,
} from "../../modules/actions";
import { isEmptyOrNull } from "../../utils/inputvalidation";
import Tree from "../../components/Tree/Tree";
import {
  setBreadCumpNavigationData,
  setSelectedWorkoutData,
  storeVideoListAndUpdate,
  toggleVideoUploadStatus,
} from "../../modules/actions/dashboardAction";
import theme from "../../constants/Theme";
import useStyles from "../../constants/Theme/styles";

import commontext from "../../constants/commontext";
import TablePaginationActions from "../../components/Table/TablePaginationActions";
import UploadVideo from "../../components/UploadVideo";
import AppHeader from "../../components/AppHeader";
import UploadProgressNotification from "../../components/UploadProgressNotification";
import {
  capFirst,
  getKeyPrefix,
  getKeyPrefixForm3u8,
  getKeyPrefixFormStreamingFolder,
  getUUID,
  uid,
} from "../../utils/helper";

import AllIcons from "../../constants/icons/icons";

import _ from "lodash";
import UserPreventionModal from "../../components/UserPreventionModal";
import { CustomLoader } from "../../common/loader/loader";
import { useNavigate } from "react-router-dom";
import EditWorkout from "../../components/EditWorkout";
import DeleteVideo from "../../components/DeleteVideo";
import { collection, doc, getDocs } from "firebase/firestore";
import { FirestoreDb } from "../../firebaseutils/firebase";
import axios from "axios";
import PromoCodesTable from "../../components/PromoCodesTable/index";
import PromoCodesAccordion from "../../components/PromoCodesTable/PromoCodesAccordion";
import DashBoardTable from "../../components/DashBoardTable/DashBoardTable";

const styles = {
  centeredContent: {
    [theme.breakpoints.up("xl")]: {
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      width: 1,
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.tableEvenRow.evenRowColor,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
var isVideoInProgress = false;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteVideoModal, setDeleteVideoModal] = React.useState(false);
  const [deleteVideoRow, setDeleteVideoRow] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteVideoModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteVideoModal(false);
  };

  const handleSingleRow = (row) => {
    setDeleteVideoRow(row);
  };

  const dispatch = useDispatch();
  var workoutVideosList = useSelector(
    (state) => state.dashboard.workoutVideosList
  );
  const selectedWorkoutData = useSelector(
    (state) => state.dashboard.selectedWorkoutData
  );
  const breadCumpNavData = useSelector(
    (state) => state.dashboard.breadCumpNavData
  );
  const isVideoUploading = useSelector(
    (state) => state.dashboard.isVideoUploading
  );
  const workoutListData = useSelector(
    (state) => state.dashboard.workoutListData
  );

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - workoutVideosList.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let [leftMenuTreeData, setTreeStructureData] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const [localVideoUploading, setLocalVideoUploading] =
    useState(isVideoUploading);
  const [openUserPreventionModal, toggleUserPreventionModal] =
    React.useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [workoutParentList, setWorkoutParentList] = useState([]);

  let pl = [
    {
      workoutType: "beginner",
      sortIndex: 1,
    },
    {
      workoutType: "intermediate",
      sortIndex: 2,
    },
    {
      workoutType: "advanced",
      sortIndex: 3,
    },
    {
      workoutType: "expert",
      sortIndex: 4,
    },
    {
      workoutType: "miscellaneous",
      sortIndex: 5,
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    setLocalVideoUploading(isVideoUploading);
  }, [isVideoUploading]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload, {
      once: false,
      passive: true,
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    if (isVideoInProgress) {
      e.preventDefault();
      const message =
        "Are you sure you want to leave? All provided data will be lost.";
      e.returnValue = message;
      return message;
    }
  };

  /**
   * we will get all workout data
   */
  useEffect(() => {
    dispatch(showLoader());
    getAllWorkoutMainData();
  }, []);

  const getAllWorkoutMainData = () => {
    services
      .getWorkoutDataFromDatabase()
      .then((res) => {
        dispatch(
          setWorkoutListData({
            workoutListData: res.response,
            error: null,
          })
        );
        dispatch(hideLoader());
      })
      .catch((error) => {
        dispatch(setWorkoutListData({ workoutListData: [], error: error }));
        dispatch(hideLoader());
      });
  };

  /**
   * Used when workout update in store
   */
  useEffect(() => {
    if (!isEmptyOrNull(workoutListData)) {
      let workoutListDataSort = [...workoutListData];
      workoutListDataSort = workoutListDataSort.map((obj) => {
        if (obj.workoutType == "beginner") return { ...obj, sortIndex: 1 };
        else if (obj.workoutType == "intermediate")
          return { ...obj, sortIndex: 2 };
        else if (obj.workoutType == "advanced") return { ...obj, sortIndex: 3 };
        else if (obj.workoutType == "expert") return { ...obj, sortIndex: 4 };
        else if (obj.workoutType == "miscellaneous")
          return { ...obj, sortIndex: 5 };
        else return { ...obj };
      });
      workoutListDataSort = workoutListDataSort.sort(
        (a, b) => a.sortIndex - b.sortIndex
      );
      setTreeStructureData(workoutListDataSort);
    }
  }, [workoutListData]);

  /**
   * Used when any breadcumb value wil update in store
   */
  useEffect(() => {
    if (!isEmptyOrNull(breadCumpNavData)) {
      setBreadcrumbs(breadCumpNavData);
    }
  }, [breadCumpNavData]);

  useEffect(() => {}, [selectedWorkoutData]);

  const handleClick = (node) => {
    if (isVideoInProgress) {
      toggleUserPreventionModal(true);
      return;
    }

    let isLastWorkoutRecordFolder =
      node?.node && node?.node?.workoutName && node?.node?.workoutVideos;
    if (isLastWorkoutRecordFolder) {
      let finalNode = node?.node;
      let routeArray = [];
      if (finalNode?.workoutName) {
        isVideoInProgress = false;
        //level 4
        routeArray.push({
          title: finalNode?.workoutName,
          uniq_id: finalNode?.workoutId,
          level_id: 4,
          id: finalNode?.id,
        });

        if (finalNode?.parentNode?.workoutType) {
          //level 1 for expert/,miscellanous
          let level1node = finalNode?.parentNode;
          routeArray.push({
            title: level1node?.workoutType,
            uniq_id: level1node?.workoutType,
            level_id: 1,
            id: level1node?.id,
          });
        } else if (finalNode?.parentNode) {
          //level 3
          let level3node = finalNode?.parentNode;
          routeArray.push({
            title: level3node?.title,
            uniq_id: level3node?.Id,
            level_id: 3,
            id: level3node?.id,
          });
          if (level3node?.parentNode) {
            //level 2
            let level2node = level3node?.parentNode;
            routeArray.push({
              title: level2node?.title,
              uniq_id: level2node?.title,
              level_id: 2,
              id: level2node?.id,
            });
            if (level2node?.parentNode) {
              //level 1
              let level1node = level2node?.parentNode;
              routeArray.push({
                title: level1node?.workoutType,
                uniq_id: level1node?.workoutType,
                level_id: 1,
                id: level1node?.id,
              });
            }
          }
        }
      }
      let reverseArray = routeArray.reverse();
      dispatch(
        setBreadCumpNavigationData({
          breadCumpNavData: reverseArray,
        })
      );

      getPerticularWorkoutData(routeArray);
    }
  };

  const getPerticularWorkoutData = (routeArray) => {
    setTableLoader(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      services
        .getDataForPerticularWorkout(routeArray)
        .then((response) => {
          setTableLoader(false);

          if (response.success) {
            dispatch(
              setSelectedWorkoutData({
                selectedWorkoutData: response.response,
                error: null,
              })
            );
            dispatch(
              storeVideoListAndUpdate({
                workoutVideosList: response?.response?.workoutVideos,
                isWorkoutChange: true,
              })
            );
          } else {
            showSnackBar(commontext.somethingWentWrong);
          }
        })
        .catch((error) => {
          setTableLoader(false);
          dispatch(
            setSelectedWorkoutData({
              selectedWorkoutData: null,
              error: error,
            })
          );
          dispatch(
            storeVideoListAndUpdate({
              workoutVideosList: [],
            })
          );
        });
    }, 500);
  };

  const onAddButtonClick = (node) => {
    if (isVideoInProgress) {
      toggleUserPreventionModal(true);
      return;
    }

    let finalNode = node;
    let routeArray2 = [];

    setWorkoutParentList([]);

    if (finalNode?.workoutType && finalNode?.data?.title) {
      //* Expert and Miscellaneous types so directly one way to add new workout(For Level 1 add button)
      let level1node = finalNode;
      routeArray2.push({
        title: level1node?.workoutType,
        uniq_id: level1node?.workoutType,
        level_id: 1,
        type: "special",
      });
    } else if (finalNode?.workoutType) {
      //user can add new category in this section of ex."beginner", "intermediate","advanced" (For Level 1 add button)
      let level1node = finalNode;
      routeArray2.push({
        title: level1node?.workoutType,
        uniq_id: level1node?.workoutType,
        level_id: 1,
        type: "normal",
      });
    } else if (finalNode?.title && finalNode?.data) {
      // user can add new subcategory in this category of ex."Body Weight","Dumbbells/Bands",etc..(For Level 2 add button)
      routeArray2.push({
        title: finalNode?.title,
        uniq_id: finalNode?.id,
        level_id: 2,
        type: "normal",
      });
      let level1node = finalNode?.parentNode;
      routeArray2.push({
        title: level1node?.workoutType,
        uniq_id: level1node?.workoutType,
        level_id: 1,
        type: "normal",
      });
    } else if (finalNode?.title && finalNode?.workouts) {
      // user can add new workout folder in this subcategory of "Core training","HIIT","Lower body training",
      routeArray2.push({
        title: finalNode?.title,
        uniq_id: finalNode?.id,
        level_id: 3,
        type: "normal",
      });
      let level2node = finalNode?.parentNode;
      routeArray2.push({
        title: level2node?.title,
        uniq_id: level2node?.title,
        level_id: 2,
        type: "normal",
      });
      let level1node = level2node?.parentNode;
      routeArray2.push({
        title: level1node?.workoutType,
        uniq_id: level1node?.workoutType,
        level_id: 1,
        type: "normal",
      });
    }
    setWorkoutParentList(routeArray2);
    setTimeout(() => {
      setOpen(true);
    }, 200);
  };

  const getLatestDataForUpload = () => {
    return new Promise((resolve, reject) => {
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          setTimeout(() => {
            resolve({
              isSuccess: true,
              res: res.response,
            });
            dispatch(
              setWorkoutListData({
                workoutListData: res.response,
                error: null,
              })
            );
            dispatch(hideLoader());
          }, 1000);
        })
        .catch((error) => {
          dispatch(setWorkoutListData({ workoutListData: [], error: error }));
          dispatch(hideLoader());
          reject({
            isError: true,
          });
        });
    });
  };

  const onClickUploadVideo = async (
    video,
    videoDuration,
    videoTitle,
    videoDescription,
    mp4FileName
  ) => {
    var fileName = mp4FileName;
    addVideoUpdateStatusToList(
      video,
      videoDuration,
      videoTitle,
      videoDescription,
      mp4FileName
    );
    try {
      isVideoInProgress = true;
      dispatch(
        toggleVideoUploadStatus({
          isVideoUploading: true,
        })
      );

      services
        .uploadVideoToS3BucketWithProgress(
          video,
          fileName,
          breadcrumbs,
          (progressCallBack) => {
            if (progressCallBack.isSuccess && progressCallBack.isProgress) {
              addProgressBatStatusToList(progressCallBack);
            }
          }
        )
        .then((response) => {
          dispatch(hideLoader());
          if (response.success) {
            showSnackBar(commontext.videoSuccessAWS);
            addVideoCompleteStatusToList(response);
            let updatedList = workoutVideosList;
            let requiedObj = updatedList.find(
              (obj) => obj.keyPrefix == response.response.key
            );
            requiedObj = {
              ...requiedObj,
              videoStaus: commontext.complete,
              videoUrl: decodeURIComponent(response.response.location),
            };

            getLatestDataForUpload().then((response1) => {
              if (response1.isSuccess) {
                let newtempList = [...response1.res];
                let tempArrray = [];

                newtempList = newtempList.map((obj) => {
                  if (obj?.data?.title) {
                    let { data, ...rest } = obj;
                    let { workouts, ...extra } = data;
                    let tempWorkoutlist = [];
                    workouts.map((obj2) => {
                      let { parentNode, workoutVideos, ...rest2 } = obj2;
                      let tempWorkoutVideoesArray = [];
                      workoutVideos.map((obj4) => {
                        let { parentNode, ...rest3 } = obj4;
                        tempWorkoutVideoesArray.push({
                          ...rest3,
                        });
                      });
                      tempWorkoutlist.push({
                        ...rest2,
                        workoutVideos: tempWorkoutVideoesArray,
                      });
                    });
                    tempArrray.push({
                      ...rest,
                      data: {
                        ...extra,
                        workouts: tempWorkoutlist,
                      },
                    });
                  } else {
                    let { data, ...rest } = obj;
                    let temp2 = [];
                    data.map((obj2) => {
                      let { parentNode, data: data1, ...rest2 } = obj2;
                      let temp3 = [];
                      data1.map((obj3) => {
                        let { parentNode, workouts, ...rest3 } = obj3;
                        let temp4 = [];
                        workouts.map((obj4) => {
                          let { parentNode, workoutVideos, ...rest4 } = obj4;
                          let temp5 = [];
                          workoutVideos.map((obj5) => {
                            let { parentNode, ...rest5 } = obj5;
                            temp5.push({
                              ...rest5,
                            });
                          });
                          temp4.push({
                            ...rest4,
                            workoutVideos: temp5,
                          });
                        });
                        temp3.push({
                          ...rest3,
                          workouts: temp4,
                        });
                      });
                      temp2.push({
                        ...rest2,
                        data: temp3,
                      });
                    });
                    tempArrray.push({
                      ...rest,
                      data: temp2,
                    });
                  }
                });

                services
                  .getLisOfMainWorkoutWithSubmittedVideo(
                    breadcrumbs,
                    tempArrray,
                    response.response,
                    fileName,
                    videoDuration,
                    videoTitle,
                    videoDescription,
                    requiedObj
                  )
                  .then((array) => {
                    dispatch(hideLoader());
                    if (!isEmptyOrNull(array)) {
                      dispatch(showLoader());
                      services
                        .storeUploadedVideoUrlInFirestore(breadcrumbs, array)
                        .then((res) => {
                          hideLoaderAndToggleStatus();
                          if (res.success) {
                            showSnackBar(commontext.videoStoredSuccessfully);
                          } else {
                          }
                        })
                        .catch((error) => {
                          hideLoaderAndToggleStatus();
                          showSnackBar(commontext.somethingWentWrong);
                        });
                    }
                  })
                  .catch((err) => {
                    hideLoaderAndToggleStatus();
                    showSnackBar(commontext.somethingWentWrong);
                  });
              }
            });
          }
        })
        .catch((error) => {
          hideLoaderAndToggleStatus();
          showSnackBar(commontext.somethingWentWrong);
        });
    } catch (error) {
      hideLoaderAndToggleStatus();
      showSnackBar(commontext.somethingWentWrong);
    }
  };

  const addVideoUpdateStatusToList = (
    video,
    videoDuration,
    videoTitle,
    videoDescription,
    mp4FileName
  ) => {
    let tempid = uid();
    var fileName = mp4FileName;

    let formattedKeyPrefix = breadcrumbs;
    formattedKeyPrefix = formattedKeyPrefix
      .map((obj) => obj.title.replace(/[&/\\]/g, ""))
      .join("/");

    let keyPrefix = getKeyPrefix(formattedKeyPrefix, fileName);

    let selectedWorkoutVideoListNeedToUpdate = workoutVideosList;

    selectedWorkoutVideoListNeedToUpdate.push({
      aboutVideo: videoDescription,
      fileName: fileName,
      id: getUUID(),
      time: videoDuration || "",
      videoId: tempid,
      videoTitle: videoTitle,
      videoUrl: "",
      videoStaus: commontext.uploading,
      keyPrefix: keyPrefix,
      percent: 0,
    });

    dispatch(
      storeVideoListAndUpdate({
        workoutVideosList: selectedWorkoutVideoListNeedToUpdate,
      })
    );
  };

  const addVideoCompleteStatusToList = (response) => {
    let updatedList = workoutVideosList;
    updatedList.map((obj) => {
      if (obj.keyPrefix == response.response.key) {
        let newObj = Object.assign(obj, {
          ...obj,
          videoStaus: commontext.complete,
          videoUrl: response.response.location,
        });
        return newObj;
      } else {
        return obj;
      }
    });

    dispatch(
      storeVideoListAndUpdate({
        workoutVideosList: updatedList,
      })
    );
  };

  const addProgressBatStatusToList = (response) => {
    let updatedList = workoutVideosList;
    updatedList.map((obj) => {
      let progress = response.response;
      if (obj.keyPrefix == response.response.key) {
        const percent = Math.round((progress.loaded / progress.total) * 100);
        let newObj = Object.assign(obj, {
          ...obj,
          videoStaus: commontext.uploading,
          videoUrl: "",
          percent: percent,
        });
        return newObj;
      } else {
        return obj;
      }
    });
    dispatch(
      storeVideoListAndUpdate({
        workoutVideosList: updatedList,
      })
    );
    isVideoInProgress = true;
    dispatch(
      toggleVideoUploadStatus({
        isVideoUploading: true,
      })
    );
  };

  const hideLoaderAndToggleStatus = () => {
    dispatch(hideLoader());
    dispatch(
      toggleVideoUploadStatus({
        isVideoUploading: false,
      })
    );
    isVideoInProgress = false;
  };

  const onUserLeaveConfirmClick = (event) => {
    toggleUserPreventionModal(false);
  };

  const showSnackBar = (message) => {
    dispatch(
      toggleSnackBar({
        showSnackBar: true,
        message: message,
      })
    );
  };

  const onAddWorkout = (workoutData) => {
    let { workoutTitle, workoutDescription, level, enviornment } = workoutData;

    if (workoutTitle && workoutDescription) {
      dispatch(showLoader());
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          if (res.success) {
            let updatedList = getUpdatedList(res, workoutData);
            if (updatedList.length) {
              let localUpdatedList = getUpdatedList(res, workoutData, true);
              let level1Data = workoutParentList.find(
                (obj) => obj.level_id == 1
              );
              let dataNeedToStore = updatedList.find(
                (obj) => obj.workoutType == level1Data.title
              );
              services
                .storeUploadedVideoUrlInFirestore(
                  workoutParentList,
                  dataNeedToStore
                )
                .then((response) => {
                  dispatch(hideLoader());
                  if (response.success) {
                    showSnackBar(commontext.workoutAddedsuccess);
                    dispatch(
                      setWorkoutListData({
                        workoutListData: localUpdatedList,
                        error: null,
                      })
                    );

                    setTimeout(() => {
                      let workoutParentListUpdated = workoutParentList;
                      workoutParentListUpdated.push({
                        level_id: 4,
                        title: workoutTitle,
                        uniq_id: workoutTitle,
                      });
                      workoutParentListUpdated = workoutParentListUpdated.sort(
                        (a, b) => a.level_id - b.level_id
                      );
                      dispatch(
                        setBreadCumpNavigationData({
                          breadCumpNavData: workoutParentListUpdated,
                        })
                      );
                      getPerticularWorkoutData(workoutParentListUpdated);
                    }, 200);
                  }
                })
                .catch(() => {
                  dispatch(hideLoader());
                });
            } else {
              dispatch(hideLoader());
            }
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    } else if (level) {
      dispatch(showLoader());
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          if (res.success) {
            let level1Data = workoutParentList.find((obj) => obj.level_id == 1);

            let updatedList = getUpdatedListForLevel(res, workoutData);
            let localUpdatedList = getUpdatedListForLevel(
              res,
              workoutData,
              true
            );

            let dataNeedToStore = updatedList.find(
              (obj) => obj.workoutType == level1Data.title
            );
            services
              .storeUploadedVideoUrlInFirestore(
                workoutParentList,
                dataNeedToStore
              )
              .then((response) => {
                dispatch(hideLoader());
                if (response.success) {
                  dispatch(
                    setWorkoutListData({
                      workoutListData: localUpdatedList,
                      error: null,
                    })
                  );
                }
              })
              .catch((error) => {
                dispatch(hideLoader());
              });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    } else if (enviornment) {
      dispatch(showLoader());
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          if (res.success) {
            let level1Data = workoutParentList.find((obj) => obj.level_id == 1);

            let updatedList = getUpdatedListForEnvironment(res, workoutData);
            let localUpdatedList = getUpdatedListForEnvironment(
              res,
              workoutData,
              true
            );
            let dataNeedToStore = updatedList.find(
              (obj) => obj.workoutType == level1Data.title
            );
            services
              .storeUploadedVideoUrlInFirestore(
                workoutParentList,
                dataNeedToStore
              )
              .then((response) => {
                dispatch(hideLoader());
                if (response.success) {
                  dispatch(
                    setWorkoutListData({
                      workoutListData: localUpdatedList,
                      error: null,
                    })
                  );
                }
              })
              .catch((error) => {
                dispatch(hideLoader());
              });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    } else {
      dispatch(hideLoader());
    }
  };

  const getUpdatedListForLevel = (res, workoutData, isLocal) => {
    let { level } = workoutData;
    let mainListData = [...res.response];
    let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
    let updatedList = mainListData.map((obj) => {
      if (obj.workoutType == level1Data.title) {
        let mainList = [...obj?.data];
        let levelObj = {
          title: level,
          sortIndex: mainList.length + 1,
          id: getUUID(),
          data: [],
        };
        if (isLocal) {
          levelObj = {
            ...levelObj,
            needToOpen: true,
          };
        }
        mainList.push(levelObj);
        let mainObj = {
          ...obj,
          data: mainList,
        };
        if (isLocal) {
          mainObj = {
            ...mainObj,
            needToOpen: true,
          };
        }
        return mainObj;
      } else {
        return obj;
      }
    });
    return updatedList;
  };

  const getUpdatedListForEnvironment = (res, workoutData, isLocal) => {
    let { enviornment } = workoutData;
    let mainListData = [...res.response];
    let level1Data = workoutParentList.find((obj) => obj.level_id == 1);

    let updatedList = mainListData.map((obj) => {
      if (obj.workoutType == level1Data.title) {
        let level2Data = workoutParentList.find((obj) => obj.level_id == 2);
        let mainListObj = {
          ...obj,
          data: obj.data.map((obj2) => {
            if (obj2.title == level2Data.title) {
              let subList = [...obj2?.data];
              let sublistObj = {
                id: getUUID(),
                sortIndex: subList.length + 1,
                title: enviornment,
                workouts: [],
              };
              if (isLocal) {
                sublistObj = {
                  ...sublistObj,
                  needToOpen: true,
                };
              }
              subList.push(sublistObj);
              let mainObj = {
                ...obj2,
                data: subList,
              };
              if (isLocal) {
                mainObj = {
                  ...mainObj,
                  needToOpen: true,
                };
              }
              return mainObj;
            } else {
              return obj2;
            }
          }),
        };
        if (isLocal) {
          mainListObj = {
            ...mainListObj,
            needToOpen: true,
          };
        }
        return mainListObj;
      } else {
        return obj;
      }
    });
    return updatedList;
  };

  const getUpdatedList = (res, workoutData, isLocal) => {
    let { workoutTitle, workoutDescription } = workoutData;
    let mainListData = [...res.response];
    let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
    let updatedList = mainListData.map((obj) => {
      if (obj.workoutType == level1Data.title) {
        let level2Data = workoutParentList.find((obj) => obj.level_id == 2);

        if (!isEmptyOrNull(level2Data)) {
          let finalPrams = {
            ...obj,
          };
          if (isLocal) {
            finalPrams = {
              ...finalPrams,
              needToOpen: true,
            };
          }

          return {
            ...finalPrams,
            data: obj?.data?.map((obj2) => {
              if (obj2.title == level2Data.title) {
                let level3Data = workoutParentList.find(
                  (obj) => obj.level_id == 3
                );
                let finalObj2 = {
                  ...obj2,
                };
                if (isLocal) {
                  finalObj2 = {
                    ...finalObj2,
                    needToOpen: true,
                  };
                }

                return {
                  ...finalObj2,
                  data: obj2?.data?.map((obj3) => {
                    if (obj3.title == level3Data.title) {
                      let workoutList = [...obj3.workouts];
                      let params = {
                        aboutWorkout: workoutDescription,
                        id: getUUID(),
                        sortIndex: workoutList.length + 1,
                        status: "open",
                        workoutId: uid(),
                        workoutName: workoutTitle,
                        workoutVideos: [],
                      };
                      if (isLocal) {
                        params = {
                          ...params,
                          needToOpen: true,
                        };
                      }
                      workoutList.push(params);

                      let finalObj3 = {
                        ...obj3,
                        workouts: workoutList,
                      };
                      if (isLocal) {
                        finalObj3 = {
                          ...finalObj3,
                          needToOpen: true,
                        };
                      }

                      return {
                        ...finalObj3,
                      };
                    } else {
                      return obj3;
                    }
                  }),
                };
              } else {
                return {
                  ...obj2,
                };
              }
            }),
          };
        } else {
          let workoutList = [...obj?.data.workouts];

          let params = {
            aboutWorkout: workoutDescription,
            id: getUUID(),
            sortIndex: workoutList.length + 1,
            status: "open",
            workoutId: uid(),
            workoutName: workoutTitle,
            workoutVideos: [],
          };
          if (isLocal) {
            params = {
              ...params,
              needToOpen: true,
            };
          }
          workoutList.push(params);
          let finalObj = {
            ...obj,
            data: {
              ...obj.data,
              workouts: workoutList,
            },
          };
          if (isLocal) {
            finalObj = {
              ...finalObj,
              data: {
                ...finalObj.data,
                needToOpen: true,
              },
              needToOpen: true,
            };
          }
          return {
            ...finalObj,
          };
        }
      } else {
        return obj;
      }
    });

    return updatedList;
  };

  const onAddNewWorkout = (workoutData) => {
    onAddWorkout(workoutData);
  };

  const onEditButtonClick = (node) => {
    if (isVideoInProgress) {
      toggleUserPreventionModal(true);
      return;
    }

    let finalNode = node;
    let routeArray2 = [];
    setWorkoutParentList([]);

    if (finalNode?.title && finalNode?.data) {
      // user can add new subcategory in this category of ex."Body Weight","Dumbbells/Bands",etc..(For Level 2 add button)
      routeArray2.push({
        title: finalNode?.title,
        uniq_id: finalNode?.id,
        level_id: 2,
        type: "normal",
        isEdit: true,
      });
      let level1node = finalNode?.parentNode;
      routeArray2.push({
        title: level1node?.workoutType,
        uniq_id: level1node?.id,
        level_id: 1,
        type: "normal",
      });
    } else if (finalNode?.title && finalNode?.workouts) {
      // user can add new workout folder in this subcategory of "Core training","HIIT","Lower body training",
      routeArray2.push({
        title: finalNode?.title,
        uniq_id: finalNode?.id,
        level_id: 3,
        type: "normal",
        isEdit: true,
      });
      let level2node = finalNode?.parentNode;
      routeArray2.push({
        title: level2node?.title,
        uniq_id: level2node?.title,
        level_id: 2,
        type: "normal",
      });
      let level1node = level2node?.parentNode;
      routeArray2.push({
        title: level1node?.workoutType,
        uniq_id: level1node?.id,
        level_id: 1,
        type: "normal",
      });
    } else if (finalNode?.workoutName) {
      let level3node = finalNode?.parentNode;
      if (level3node?.data?.title) {
        routeArray2.push({
          title: finalNode?.workoutName,
          uniq_id: finalNode?.id,
          level_id: 4,
          type: "special",
          isEdit: true,
        });
        routeArray2.push({
          title: level3node?.workoutType,
          uniq_id: level3node?.id,
          level_id: 1,
          type: "special",
        });
      } else {
        routeArray2.push({
          title: finalNode?.workoutName,
          uniq_id: finalNode?.id,
          level_id: 4,
          type: "normal",
          isEdit: true,
        });
        routeArray2.push({
          title: level3node?.title,
          uniq_id: level3node?.id,
          level_id: 3,
          type: "normal",
        });
        let level2node = level3node?.parentNode;
        routeArray2.push({
          title: level2node?.title,
          uniq_id: level2node?.id,
          level_id: 2,
          type: "normal",
        });
        let level1node = level2node?.parentNode;
        routeArray2.push({
          title: level1node?.workoutType,
          uniq_id: level1node?.id,
          level_id: 1,
          type: "normal",
        });
      }
    }
    setWorkoutParentList(routeArray2);
    setTimeout(() => {
      setOpenEditModal(true);
    }, 200);
  };

  const onEditNewWorkout = (workoutData) => {
    let { workoutTitle, level, enviornment } = workoutData;
    if (workoutTitle) {
      dispatch(showLoader());
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          dispatch(hideLoader());
          if (res.success) {
            let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
            let updatedList = getUpdatedListForUpdatedWorkoutTitle(
              res,
              workoutData
            );
            let localUpdatedList = getUpdatedListForUpdatedWorkoutTitle(
              res,
              workoutData,
              true
            );
            let dataNeedToStore = updatedList.find(
              (obj) => obj.workoutType == level1Data.title
            );
            services
              .storeUploadedVideoUrlInFirestore(
                workoutParentList,
                dataNeedToStore
              )
              .then((response) => {
                dispatch(hideLoader());
                if (response.success) {
                  let level4Data = workoutParentList.find(
                    (obj) => obj.level_id == 4
                  );

                  let breadcrumbList = breadcrumbs;
                  let updatedBreadCumbList = breadcrumbList.map((obj) => {
                    if (obj.id == level4Data.uniq_id) {
                      return {
                        ...obj,
                        title: workoutTitle,
                      };
                    } else {
                      return obj;
                    }
                  });
                  dispatch(
                    setBreadCumpNavigationData({
                      breadCumpNavData: updatedBreadCumbList,
                    })
                  );

                  dispatch(
                    setWorkoutListData({
                      workoutListData: localUpdatedList,
                      error: null,
                    })
                  );
                }
              })
              .catch(() => {
                dispatch(hideLoader());
              });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    } else if (level) {
      dispatch(showLoader());
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          dispatch(hideLoader());
          let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
          let level2Data = workoutParentList.find((obj) => obj.level_id == 2);
          if (res.success) {
            let updatedList = getUpdatedListForUpdatedLevel(res, workoutData);
            let localUpdatedList = getUpdatedListForUpdatedLevel(
              res,
              workoutData,
              true
            );
            let dataNeedToStore = updatedList.find(
              (obj) => obj.workoutType == level1Data.title
            );
            services
              .storeUploadedVideoUrlInFirestore(
                workoutParentList,
                dataNeedToStore
              )
              .then((response) => {
                let breadcrumbList = breadcrumbs;
                let updatedBreadCumbList = breadcrumbList.map((obj) => {
                  if (obj.id == level2Data.uniq_id) {
                    return {
                      ...obj,
                      title: level,
                    };
                  } else {
                    return obj;
                  }
                });
                dispatch(
                  setBreadCumpNavigationData({
                    breadCumpNavData: updatedBreadCumbList,
                  })
                );
                dispatch(hideLoader());
                if (response.success) {
                  dispatch(
                    setWorkoutListData({
                      workoutListData: localUpdatedList,
                      error: null,
                    })
                  );
                }
              })
              .catch(() => {
                dispatch(hideLoader());
              });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    } else if (enviornment) {
      dispatch(showLoader());
      services
        .getWorkoutDataFromDatabase()
        .then((res) => {
          dispatch(hideLoader());
          let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
          if (res.success) {
            let updatedList = getUpdatedListForUpdatedEnvironment(
              res,
              workoutData
            );
            let localUpdatedList = getUpdatedListForUpdatedEnvironment(
              res,
              workoutData,
              true
            );
            let dataNeedToStore = updatedList.find(
              (obj) => obj.workoutType == level1Data.title
            );
            services
              .storeUploadedVideoUrlInFirestore(
                workoutParentList,
                dataNeedToStore
              )
              .then((response) => {
                dispatch(hideLoader());
                if (response.success) {
                  let level3Data = workoutParentList.find(
                    (obj) => obj.level_id == 3
                  );

                  let breadcrumbList = breadcrumbs;
                  let updatedBreadCumbList = breadcrumbList.map((obj) => {
                    if (obj.id == level3Data.uniq_id) {
                      return {
                        ...obj,
                        title: enviornment,
                      };
                    } else {
                      return obj;
                    }
                  });
                  dispatch(
                    setBreadCumpNavigationData({
                      breadCumpNavData: updatedBreadCumbList,
                    })
                  );

                  dispatch(
                    setWorkoutListData({
                      workoutListData: localUpdatedList,
                      error: null,
                    })
                  );
                }
              })
              .catch(() => {
                dispatch(hideLoader());
              });
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    } else {
      dispatch(hideLoader());
    }
  };

  const getUpdatedListForUpdatedLevel = (res, workoutData, isLocal) => {
    let { level } = workoutData;
    let mainListData = [...res.response];
    let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
    let level2Data = workoutParentList.find((obj) => obj.level_id == 2);

    let updatedList = mainListData.map((obj) => {
      if (obj.workoutType == level1Data.title) {
        let mainList = [...obj?.data];
        let updatedMainList = mainList.map((obj2) => {
          if (obj2.id == level2Data.uniq_id) {
            let mainObj2 = {
              ...obj2,
              title: level,
            };
            if (isLocal) {
              mainObj2 = {
                ...mainObj2,
                needToOpen: true,
              };
            }
            return mainObj2;
          } else {
            return obj2;
          }
        });
        let mainObj = {
          ...obj,
          data: updatedMainList,
        };
        if (isLocal) {
          mainObj = {
            ...mainObj,
            needToOpen: true,
          };
        }
        return mainObj;
      } else {
        return obj;
      }
    });
    return updatedList;
  };

  const getUpdatedListForUpdatedEnvironment = (res, workoutData, isLocal) => {
    let { enviornment } = workoutData;
    let mainListData = [...res.response];
    let level1Data = workoutParentList.find((obj) => obj.level_id == 1);

    let updatedList = mainListData.map((obj) => {
      if (obj.workoutType == level1Data.title) {
        let level2Data = workoutParentList.find((obj) => obj.level_id == 2);
        let mainList = [...obj?.data];
        let updatedMainList = mainList.map((obj2) => {
          if (obj2.title == level2Data.title) {
            let level3Data = workoutParentList.find((obj) => obj.level_id == 3);
            let subList = [...obj2.data];
            let updatedSubList = subList.map((obj3) => {
              if (obj3.id == level3Data.uniq_id) {
                let mainObj3 = {
                  ...obj3,
                  title: enviornment,
                };
                if (isLocal) {
                  mainObj3 = {
                    ...mainObj3,
                    needToOpen: true,
                  };
                }
                return mainObj3;
              } else {
                return obj3;
              }
            });
            let mainObj2 = {
              ...obj2,
              data: updatedSubList,
            };
            if (isLocal) {
              mainObj2 = {
                ...mainObj2,
                needToOpen: true,
              };
            }
            return mainObj2;
          } else {
            return obj2;
          }
        });
        let mainObj = {
          ...obj,
          data: updatedMainList,
        };
        if (isLocal) {
          mainObj = {
            ...mainObj,
            needToOpen: true,
          };
        }
        return mainObj;
      } else {
        return obj;
      }
    });
    return updatedList;
  };

  const getUpdatedListForUpdatedWorkoutTitle = (res, workoutData, isLocal) => {
    let { workoutTitle } = workoutData;
    let mainListData = [...res.response];
    let level1Data = workoutParentList.find((obj) => obj.level_id == 1);
    let updatedList = mainListData.map((obj) => {
      if (obj.workoutType == level1Data.title) {
        let level2Data = workoutParentList.find((obj) => obj.level_id == 2);
        if (!isEmptyOrNull(level2Data)) {
          let mainList = [...obj?.data];

          let updatedMainList = mainList.map((obj2) => {
            if (obj2.title == level2Data.title) {
              let level3Data = workoutParentList.find(
                (obj) => obj.level_id == 3
              );
              let subList = [...obj2.data];
              let updatedSubList = subList.map((obj3) => {
                if (obj3.title == level3Data.title) {
                  let level4Data = workoutParentList.find(
                    (obj) => obj.level_id == 4
                  );
                  let workoutList = [...obj3.workouts];
                  let updatedWorkoutList = workoutList.map((obj4) => {
                    if (obj4.id == level4Data.uniq_id) {
                      let mainObj4 = {
                        ...obj4,
                        workoutName: workoutTitle,
                      };
                      if (isLocal) {
                        mainObj4 = {
                          ...mainObj4,
                          needToOpen: true,
                        };
                      }
                      return mainObj4;
                    } else {
                      return obj4;
                    }
                  });
                  let mainObj3 = {
                    ...obj3,
                    workouts: updatedWorkoutList,
                  };
                  if (isLocal) {
                    mainObj3 = {
                      ...mainObj3,
                      needToOpen: true,
                    };
                  }
                  return mainObj3;
                } else {
                  return obj3;
                }
              });
              let mainObj2 = {
                ...obj2,
                data: updatedSubList,
              };
              if (isLocal) {
                mainObj2 = {
                  ...mainObj2,
                  needToOpen: true,
                };
              }
              return mainObj2;
            } else {
              return obj2;
            }
          });
          let mainObj = {
            ...obj,
            data: updatedMainList,
          };
          if (isLocal) {
            mainObj = {
              ...mainObj,
              needToOpen: true,
            };
          }
          return mainObj;
        } else {
          //*expert type
          let workoutList = [...obj?.data.workouts];
          let level4Data = workoutParentList.find((obj) => obj.level_id == 4);

          let updatedWorkoutList = workoutList.map((obj2) => {
            if (obj2.id == level4Data.uniq_id) {
              let mainObj2 = {
                ...obj2,
                workoutName: workoutTitle,
              };
              if (isLocal) {
                mainObj2 = {
                  ...mainObj2,
                  needToOpen: true,
                };
              }
              return mainObj2;
            } else {
              return obj2;
            }
          });
          let finalObj = {
            ...obj,
            data: {
              ...obj.data,
              workouts: updatedWorkoutList,
            },
          };
          if (isLocal) {
            finalObj = {
              ...finalObj,
              data: {
                ...finalObj.data,
                needToOpen: true,
              },
              needToOpen: true,
            };
          }
          return finalObj;
        }
      } else {
        return obj;
      }
    });
    return updatedList;
  };

  const onClickDeleteVideo = async (data) => {
    if (isVideoInProgress) {
      toggleUserPreventionModal(true);
      return;
    }

    var fileName = data?.fileName;
    const url = decodeURIComponent(data.videoUrl);

    const urlObj = new URL(url);

    let newPathList = urlObj?.pathname
      .substring(1)
      .replace(/\+/g, " ")
      .replace(/%20/g, " ");

    let list1 = newPathList.split("/");

    var keyList = list1.slice(0, -1).join("/");

    // return;
    try {
      dispatch(showLoader());
      closeDeleteModal();
      services
        .getPurchasedVideosList()
        .then(({ response }) => {
          if (response.status == 200) {
            let idList = [...response.data];
            let uniqList = _.uniqBy(idList);
            let selectedIdList = uniqList.filter((obj) => obj == data?.videoId);
            // dispatch(hideLoader());
            services
              .getWorkoutDataFromDatabase()
              .then((value) => {
                let updatedList = getWorkoutListForVideoDelete(
                  value,
                  data,
                  false
                );
                let localUpdatedList = getWorkoutListForVideoDelete(
                  value,
                  data,
                  true
                );
                let mainWorkout = breadcrumbs.find((obj) => obj.level_id === 1);
                let dataNeedToStore = updatedList.find(
                  (obj) => obj.workoutType == mainWorkout.title
                );
                dispatch(showLoader());

                // return;

                services
                  .storeUploadedVideoUrlInFirestore(
                    breadcrumbs,
                    dataNeedToStore
                  )
                  .then((response) => {
                    if (response.success) {
                      dispatch(
                        setWorkoutListData({
                          workoutListData: localUpdatedList,
                          error: null,
                        })
                      );
                      if (selectedIdList.length) {
                        showSnackBar(commontext.videoDeletedSuccessfully);
                        setTimeout(() => {
                          getPerticularWorkoutData(breadcrumbs);
                          dispatch(hideLoader());
                        }, 1000);
                      } else {
                        services
                          .deleteVideoFromAws(fileName, keyList)
                          .then((response) => {
                            showSnackBar(commontext.videoDeletedSuccessfully);
                            setTimeout(() => {
                              getPerticularWorkoutData(breadcrumbs);
                              dispatch(hideLoader());
                            }, 1000);
                          })
                          .catch((error) => {
                            console.log("Error:222", error);
                            dispatch(hideLoader());
                          });
                      }
                    } else {
                      showSnackBar(commontext.somethingWentWrong);
                      dispatch(hideLoader());
                    }
                  });
              })
              .catch((error) => {
                dispatch(hideLoader());
                console.log("Error:", error);
                showSnackBar(commontext.somethingWentWrong);
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((error) => {
          console.error("error===> ", error);
          dispatch(hideLoader());
        });
    } catch (error) {
      console.log("Error :", error);
      dispatch(hideLoader());
    }
  };

  const getWorkoutListForVideoDelete = (value, videoData, isLocal) => {
    let mainWorkout = breadcrumbs.find((obj) => obj.level_id === 1);
    let mainEnvWorkout = breadcrumbs.find((obj) => obj.level_id === 2);
    let subEnvWorkout = breadcrumbs.find((obj) => obj.level_id === 3);
    let finalWorkoutData = breadcrumbs.find((obj) => obj.level_id === 4);
    let allEnvData = [...value.response];
    let updatedList = [];
    if (mainEnvWorkout) {
      updatedList = allEnvData.map((obj) => {
        if (obj.workoutType == mainWorkout.title) {
          let level2Data = [...obj.data];
          level2Data = level2Data.map((obj2) => {
            if (obj2.title == mainEnvWorkout.title) {
              let level3Data = [...obj2.data];
              level3Data = level3Data.map((obj3) => {
                if (obj3.title == subEnvWorkout.title) {
                  let level4Data = [...obj3.workouts];
                  level4Data = level4Data.map((obj4) => {
                    if (obj4.workoutName == finalWorkoutData.title) {
                      let level4List = [...obj4.workoutVideos];
                      level4List = level4List.filter(
                        (item) => item.videoId != videoData.videoId
                      );

                      let level4Obj = {
                        ...obj4,
                        workoutVideos: level4List,
                      };
                      if (isLocal) {
                        level4Obj = {
                          ...level4Obj,
                          needToOpen: true,
                        };
                      }
                      return level4Obj;
                    } else {
                      return obj4;
                    }
                  });
                  let level3Obj = {
                    ...obj3,
                    workouts: level4Data,
                  };
                  if (isLocal) {
                    level3Obj = {
                      ...level3Obj,
                      needToOpen: true,
                    };
                  }
                  return level3Obj;
                } else {
                  return obj3;
                }
              });
              let level2Obj = {
                ...obj2,
                data: level3Data,
              };
              if (isLocal) {
                level2Obj = {
                  ...level2Obj,
                  needToOpen: true,
                };
              }
              return level2Obj;
            } else {
              return obj2;
            }
          });
          let level1Obj = {
            ...obj,
            data: level2Data,
          };
          if (isLocal) {
            level1Obj = {
              ...level1Obj,
              needToOpen: true,
            };
          }
          return level1Obj;
        } else {
          return obj;
        }
      });
    } else {
      updatedList = allEnvData.map((obj) => {
        if (obj.workoutType == mainWorkout.title) {
          let level4Data = [...obj?.data?.workouts];
          level4Data = level4Data.map((obj4) => {
            if (obj4.workoutName == finalWorkoutData.title) {
              let level4List = [...obj4.workoutVideos];
              level4List = level4List.filter(
                (item) => item.videoId != videoData.videoId
              );
              let level4Obj = {
                ...obj4,
                workoutVideos: level4List,
              };
              if (isLocal) {
                level4Obj = {
                  ...level4Obj,
                  needToOpen: true,
                };
              }
              return level4Obj;
            } else {
              return obj4;
            }
          });
          let level1Obj = {
            ...obj,
            data: {
              ...obj.data,
              workouts: level4Data,
            },
          };
          if (isLocal) {
            level1Obj = {
              ...level1Obj,
              data: {
                ...level1Obj.data,
                needToOpen: true,
              },
              needToOpen: true,
            };
          }
          return level1Obj;
        } else {
          return obj;
        }
      });
    }
    return updatedList;
  };

  return (
    <PageWrapper>
      <AppHeader
        isVideoInProgress={isVideoInProgress}
        showWarningModal={() => toggleUserPreventionModal(true)}
      />

      <TabWrapper>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "200px",
              marginTop: "3px",
            }}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Videos" {...a11yProps(0)} />
              <Tab label="Promo Codes" {...a11yProps(1)} />
              <Tab label="DashBoard" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack sx={styles.centeredContent} py={3}>
              <Grid
                container
                spacing={2}
                px={2}
                sx={{ maxWidth: theme.breakpoints.values.xl }}
              >
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.palette.common.white,
                      color: (theme) => theme.palette.common.black,
                      height: "calc( 100% – div_height)",
                    }}
                  >
                    <Tree
                      data={leftMenuTreeData.length ? leftMenuTreeData : pl}
                      onNodeClick={handleClick}
                      onAddButtonClick={onAddButtonClick}
                      onEditButtonClick={onEditButtonClick}
                    />
                  </Box>
                </Grid>
                <Grid item xl={9} lg={8} md={8} sm={12} xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {breadcrumbs.length ? (
                      <Breadcrumbs
                        separator="›"
                        aria-label="breadcrumb"
                        variant="breadCrumbs"
                        sx={{
                          color: (theme) => theme.palette.common.white,
                          display: "inline-block",
                        }}
                      >
                        <Typography>{"Workouts"}</Typography>
                        {breadcrumbs.map((obj) => {
                          return <Typography>{capFirst(obj.title)}</Typography>;
                        })}
                      </Breadcrumbs>
                    ) : null}
                    {(workoutVideosList && workoutVideosList.length > 0) ||
                    breadcrumbs.length ? (
                      <UploadVideo
                        onUploadVideo={(
                          video,
                          videoDuration,
                          videoTitle,
                          videoDescription,
                          mp4FileName
                        ) => {
                          onClickUploadVideo(
                            video,
                            videoDuration,
                            videoTitle,
                            videoDescription,
                            mp4FileName
                          );
                        }}
                      />
                    ) : null}
                  </Box>

                  {isVideoInProgress ? <UploadProgressNotification /> : null}
                  <Box mt={2} mb={7} sx={{ position: "relative" }}>
                    {workoutVideosList && workoutVideosList.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell width="25%">
                                {commontext.videoNameinApp}
                              </StyledTableCell>
                              <StyledTableCell width="45%">
                                {commontext.fileNameOfAwsS3}
                              </StyledTableCell>
                              <StyledTableCell width="20%">
                                {commontext.uploadStatus}
                              </StyledTableCell>
                              <StyledTableCell width="10%">
                                {commontext.action}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(rowsPerPage > 0
                              ? workoutVideosList.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : workoutVideosList
                            ).map((row) => (
                              <>
                                <StyledTableRow>
                                  <StyledTableCell
                                    component="th"
                                    scope="col"
                                    width="25%"
                                  >
                                    {row.videoTitle}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    component="th"
                                    scope="col"
                                    width="45%"
                                  >
                                    <Tooltip title={row.videoUrl} arrow>
                                      <VideoUrl>{row.videoUrl}</VideoUrl>
                                    </Tooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="th"
                                    scope="col"
                                    width="20%"
                                    sx={{ display: "flex" }}
                                  >
                                    {row.videoStaus == commontext.uploading ? (
                                      <AllIcons.CloudUploadIcon
                                        sx={{
                                          color: `${theme.palette.uploadStatus.uploadingColor}`,
                                        }}
                                      />
                                    ) : (
                                      <AllIcons.CloudDoneIcon
                                        sx={{
                                          color: `${theme.palette.uploadStatus.successColor}`,
                                        }}
                                      />
                                    )}
                                    <Box
                                      sx={{
                                        ml: 1,
                                        mt: "2px",
                                        color:
                                          row.videoStaus == commontext.uploading
                                            ? theme.palette.uploadStatus
                                                .uploadingColor
                                            : theme.palette.uploadStatus
                                                .successColor,
                                      }}
                                    >
                                      {row.videoStaus == commontext.uploading
                                        ? commontext.uploading
                                        : commontext.complete}
                                    </Box>
                                    <Box
                                      sx={{
                                        color:
                                          row.videoStaus == commontext.uploading
                                            ? theme.palette.uploadStatus
                                                .uploadingColor
                                            : theme.palette.uploadStatus
                                                .successColor,
                                      }}
                                    >
                                      {!isEmptyOrNull(row.percent) &&
                                      row.videoStaus == commontext.uploading
                                        ? "(" + row.percent + "%)"
                                        : null}
                                    </Box>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="th"
                                    scope="col"
                                    width="10%"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      openDeleteModal();
                                      handleSingleRow(row);
                                    }}
                                  >
                                    <Tooltip title={"Delete Video"} arrow>
                                      <AllIcons.DeleteIcon />
                                    </Tooltip>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>

                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[
                                  5,
                                  10,
                                  25,
                                  { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={workoutVideosList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: {
                                    "aria-label": "workoutVideosList per page",
                                  },
                                  native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Box className={classes.centerScreenText}>
                        <Typography>
                          {breadcrumbs.length
                            ? commontext.emptyWorkoutText
                            : commontext.selectCategoryText}
                        </Typography>
                      </Box>
                    )}
                    <CustomLoader isLoading={tableLoader} />
                  </Box>
                </Grid>
              </Grid>
              <NewWorkout
                open={open}
                close={handleClickClose}
                selectedParentNodeData={workoutParentList}
                onAddNewWorkout={onAddNewWorkout}
              />
              <EditWorkout
                open={openEditModal}
                close={() => setOpenEditModal(false)}
                selectedParentNodeData={workoutParentList}
                onEditNewWorkout={onEditNewWorkout}
              />
              <DeleteVideo
                open={deleteVideoModal}
                close={closeDeleteModal}
                rowData={deleteVideoRow}
                onClickDelete={onClickDeleteVideo}
              />
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PromoCodesAccordion />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DashBoardTable />
          </TabPanel>
        </Box>
      </TabWrapper>

      <UserPreventionModal
        isOpen={openUserPreventionModal}
        handleClose={() => toggleUserPreventionModal(false)}
        onUserLeaveConfirmClick={onUserLeaveConfirmClick}
      />
    </PageWrapper>
  );
};

export default Dashboard;
