import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
    userData: null,
    error: null,
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AUTH_DATA: {
            return updateObject(state, action.payload);
            break;
        }

        case actionTypes.RESET_STORE:
            return initialState;
            break;

        default:
            return state;
    }
}

export default auth;