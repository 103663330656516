import { createTheme } from "@mui/material";
import { themeTypography } from "./typography";
import { themeInputs } from "./input";
import { themeButtons } from "./button";
import { themeLayout } from "./layout";
import { themePalette } from "./colors";
import { themeTable } from "./tables";
import { themeDialog } from "./dialog";

const theme = createTheme(
  themeTypography,
  themeInputs,
  themeButtons,
  themeLayout,
  themePalette,
  themeTable,
  themeDialog
);

export default theme;
