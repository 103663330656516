import { createTheme } from "@mui/material/styles";
import { themePalette } from "./colors";

export const themeDialog = createTheme(themePalette, {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "23px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
          padding: "15px 20px 10px",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: `${themePalette.palette.dialogContent.dialogText} !important`,
          fontSize: "14px",
        },
      },
    },
  },
});
