import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    IconButton,
    Collapse,
    TableHead,
} from "@mui/material";
import AllIcons from "../../../constants/icons/icons";
import PromoCodeDiscountRow from "./PromoCodeDiscountRow";
import commontext from "../../../constants/commontext";
import theme from "../../../constants/Theme";

export default function Row(props) {
    const { row, editToggle, closeToogle, rowIndex, onUpdateRow, allPromoData, isEditModeOn } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{
                cursor: "pointer", "&:hover": { backgroundColor: theme.palette.tableEvenRow.evenRowColor },
                "& .MuiTableCell-root": {
                    borderBottom: `1px solid lightgrey`,
                },
            }} onClick={() => setOpen(!open)}>
                <TableCell width={"25%"}>Pack of {row.pack} Video</TableCell>
                <TableCell width={"25%"}></TableCell>
                <TableCell width={"25%"}></TableCell>
                <TableCell
                    width={"25%"}
                    sx={{ paddingLeft: "10px", textAlign: "right" }}
                >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <AllIcons.KeyboardArrowUpIcon />
                        ) : (
                            <AllIcons.KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={"16%"} sx={{ pl: 1.25, py: 1 }}>
                                            {commontext.discountHeading}
                                        </TableCell>
                                        <TableCell width={"20%"} sx={{ pl: 1.25, py: 1 }}>
                                            {commontext.promoCodeHeading}
                                        </TableCell>
                                        <TableCell width={"20%"} sx={{ pl: 1.25, py: 1 }}>
                                            Allowed # of times
                                        </TableCell>
                                        <TableCell width={"16%"} sx={{ pl: 1.25, py: 1 }}>
                                            {commontext.statusHeading}
                                        </TableCell>
                                        <TableCell
                                            width={"16%"}
                                            sx={{ pl: 1.25, py: 1 }}
                                        ></TableCell>
                                        <TableCell
                                            width={"16%"}
                                            sx={{ pl: 1.25, py: 1 }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.discount_list?.map((data, index) => (
                                        <PromoCodeDiscountRow
                                            data={data}
                                            isEditModeOn={isEditModeOn}
                                            index={index}
                                            editToggle={(toggleValue) => editToggle(index, toggleValue)}
                                            onUpdateRow={(value, isSwitchOn, allowedTimes) => onUpdateRow(index, value, data.id, isSwitchOn, allowedTimes)}
                                            allPromoData={allPromoData}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
