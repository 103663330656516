import styled from "@emotion/styled";
import { themePalette } from "../../constants/Theme/colors";

export const ActionButtonWrapper = styled.div(({}) => {
  return {
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "unset",
      },
    },
  };
});

export const CancelButtonWrapper = styled.div(({}) => {
  return {
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: themePalette.palette.common.black,
        color: themePalette.palette.common.white,
        opacity: 0.7,
        transition: "0.4s ease-in-out",
      },
    },
  };
});
