import React, {
    useEffect,
    useState,
} from "react";
import {
    TableCell,
    TableRow,
    IconButton,
    TextField,
    Stack,
} from "@mui/material";
import AllIcons from "../../../constants/icons/icons";
import PackActions from "./packActions";
import { EditFieldWrapper } from "../style";
import commontext from "../../../constants/commontext";
import { styled } from "@mui/material/styles";
import theme from "../../../constants/Theme";
import { Switch } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: `${theme.palette.tableEvenRow.evenRowColor}`,
    },
}));

export default function PromoCodeDiscountRow({
    data,
    index,
    editToggle,
    onUpdateRow,
    allPromoData,
    isEditModeOn,
}) {
    const [val, setVal] = useState(String(data['code']));
    const [isSwitchOn, setIsSwitchOn] = useState(data['status'])
    const [allowedTimes, setAllowedTimes] = useState(Number(data['allowed_times']))
    const [helperText, setHelperText] = useState("");
    const [allPromoList, setAllPromoList] = useState([...allPromoData].filter((obj) => obj.id != data.id))

    const { isEditMode } = data;

    useEffect(() => {
        setVal(String(data['code']))
        setIsSwitchOn(data['status'])
        setAllowedTimes(Number(data['allowed_times']))
    }, [data])

    const getDisabledStatus = () => {
        let text = val;
        let isCodeAlreadyExist = [...allPromoList].filter(obj => obj.code == text)
        if (val.trim().length < 5) {
            return true
        }
        else if (isCodeAlreadyExist.length) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <StyledTableRow key={data.id}>
            <TableCell
                component="th"
                scope="row"
                sx={{ pl: 1 }}
            >
                ${data.discount} less
            </TableCell>
            <TableCell align="left" sx={{ pl: 1 }}>
                {
                    isEditMode
                        ? (
                            <EditFieldWrapper>
                                <TextField
                                    value={val}
                                    name={'code'}
                                    onChange={e => {
                                        let text = e.target.value.toUpperCase().replace(/[^a-z0-9 .]/gi, "");
                                        setVal(text);

                                        let isCodeAlreadyExist = [...allPromoList].filter(obj => obj.code == text)
                                        if (!e.target.value.length) {
                                            setHelperText(commontext.promoCodeValidation)
                                        }
                                        else if (e.target.value.length < 5) {
                                            setHelperText(commontext.promoCodeLength);
                                        }
                                        else if (isCodeAlreadyExist.length) {
                                            setHelperText(commontext.uniquePromoCode)
                                        }
                                        else {
                                            setHelperText("")
                                        }
                                    }}
                                    placeholder={commontext.promoCodePlaceholder}
                                    variant="standard"
                                    type={"text"}
                                    helperText={helperText}
                                    sx={{
                                        input: {
                                            "&::placeholder": {
                                                textTransform: "capitalize",
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 5,
                                            style: { textTransform: "uppercase" },
                                        },
                                    }}
                                />
                            </EditFieldWrapper>
                        )
                        :
                        (data['code'])
                }
            </TableCell>
            <TableCell>
                {isEditMode ? (
                    <EditFieldWrapper>
                        <TextField
                            defaultValue={data['allowed_times'] ? data['allowed_times'] : 1}
                            value={allowedTimes}
                            name={'allowed_times'}
                            variant="standard"
                            onChange={e => setAllowedTimes(Number(e.target.value))}
                            type={"number"}
                            InputProps={{
                                inputProps: {
                                    min: 1,
                                    max: 5,
                                },
                            }}
                            placeholder={"Enter value for times allowed"}
                            onKeyDown={event => {
                                event.preventDefault();
                            }}
                        />
                    </EditFieldWrapper>
                ) : (
                    data['allowed_times'] ? data['allowed_times'] : 1
                )}
            </TableCell>
            <TableCell component="th" scope="row" sx={{ pl: 1.5 }}>
                {data.status ? (
                    <Stack sx={{ display: "inline", color: "#54CE35" }}>{commontext.liveText}</Stack>
                ) : (<Stack sx={{ display: "inline" }}>{commontext.turnedOff}</Stack>)}
            </TableCell>
            <TableCell component="th" scope="row">
                {isEditMode ? (
                    <Switch checked={isSwitchOn}
                        onChange={(e) => {
                            setIsSwitchOn(e.target.checked)
                        }}
                        name={'status'} />
                ) : (
                    data['status']
                )}
            </TableCell>
            <TableCell
                component="th"
                scope="row"
                width={"25%"}
                sx={{ pl: 1, textAlign: "right" }}
            >
                {data.isEditMode ? (
                    <>
                        <IconButton
                            aria-label="done"
                            disabled={getDisabledStatus()}
                            onClick={() => onUpdateRow(val.toUpperCase(), isSwitchOn, allowedTimes)}
                        >
                            <AllIcons.DoneAllIcon />
                        </IconButton>
                        <IconButton
                            aria-label="revert"
                            onClick={() => {
                                setVal(String(data['code']))
                                setIsSwitchOn(data['status'])
                                setAllowedTimes(Number(data['allowed_times']))
                                editToggle(false)
                            }}
                        >
                            <AllIcons.CancelIcon />
                        </IconButton>
                    </>
                ) : (
                    <PackActions
                        disabled={isEditModeOn}
                        index={index}
                        toggleMode={() => editToggle(true)}
                    />
                )}
            </TableCell>
        </StyledTableRow>
    )
}