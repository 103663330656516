import { createTheme } from "@mui/material/styles";

export const themePalette = createTheme({
  palette: {
    type: "light",
    errorMsg: {
      main: "#BA1B1B",
    },

    mainContainer: {
      greyBackground: "#262626",
    },

    appHeader: {
      gradientBg: "linear-gradient(180deg, rgba(10, 10, 10) 0%, #262626 100%)",
    },

    textStyle: {
      textColor: "#FFFFFF",
    },

    inputField: {
      inputBeforeColor: "#FFFFFF",
      inputAfterColor: "#ABABAB",
      inputTextColor: "#FFFFFF",
      eyeIconColor: "#ABABAB",
    },

    inputFieldLabel: {
      labelColor: "#ABABAB",
      focusedLabel: "#FFFFFF",
    },

    icon: {
      iconColor: "#FFFFFF",
    },

    eyeIcon: {
      eyeColor: "#757575",
    },

    button: {
      buttonBg: "#FFFFFF",
      buttonText: "#000000",
      buttonHoverBg: "#ABABAB",
    },

    disabledButton: {
      btnBg: "#999999",
    },

    dialogContent: {
      dialogText: "#6A6A6A",
    },

    videoFileMsg: {
      msgColor: "#999999",
    },

    loader: {
      loaderColor: "#FFFFFF",
    },

    common: {
      white: "#FFFFFF",
      black: "#000000",
    },

    logoutButton: {
      logoutBtnHover: "#787272",
    },

    treeStructure: {
      verticalLine: "#747474",
    },

    paginationSelect: {
      dropdownBackground: "rgba(0, 0, 0, 0.05)",
    },

    tableHeader: {
      titleColor: "#667085",
      bottomBorder: "#EFEFEF",
    },

    tableEvenRow: {
      evenRowColor: "#F9FAFD",
    },

    uploadStatus: {
      successColor: "#2E9709",
      uploadingColor: "#00A2FD",
    },

    uploadNotification: {
      notificationBg: "#FFDDCA",
      notificationTextColor: "#C71F1F",
    },

    editField: {
      borderColor: "#949494",
    },
  },
});
