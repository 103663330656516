import * as React from "react";
import commontext from "../../constants/commontext";
import theme from "../../constants/Theme";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "../../constants/Theme/styles";
import { isEmptyOrNull } from "../../utils/inputvalidation";
import { capFirst, } from "../../utils/helper";
import AllIcons from "../../constants/icons/icons";

export default function NewWorkout(props) {
  const CHARACTER_LIMIT = 30;
  const { open, close, selectedParentNodeData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [workoutParentList, setWorkoutParentList] = React.useState([]);

  const [workoutTitle, setWorkoutTitle] = React.useState("");
  const [workoutDescription, setWorkoutDescription] = React.useState("");
  const [level, setLevel] = React.useState("");
  const [enviornment, setEnvironement] = React.useState("");

  const [values, setValues] = React.useState({
    isTitleError: false,
    isDescriptionError: false,
    isLevelError: false,
    isEnvionmentError: false,
  });

  let routeList = [...workoutParentList];
  let mainWorkout = routeList.find(obj => obj.level_id === 1);
  let mainEnvWorkout = routeList.find(obj => obj.level_id === 2);
  let subEnvWorkout = routeList.find(obj => obj.level_id === 3);
  let showTitleAndDescription = mainWorkout?.type == "special" ? true : mainEnvWorkout && subEnvWorkout ? true : false;

  let headerTitle = mainWorkout?.type == "special" ? commontext.addNewWorkout :
    mainWorkout?.type == "normal" && !mainEnvWorkout ? commontext.addNewLevel :
      mainWorkout?.type == "normal" && mainEnvWorkout ? !subEnvWorkout ? commontext.addNewEnvrionment
        : commontext.addNewWorkout : commontext.addNewWorkout;

  /**
   * Used when any breadcumb value wil update in store
   */
  React.useEffect(() => {
    if (!isEmptyOrNull(selectedParentNodeData)) {
      setWorkoutParentList(selectedParentNodeData);
    }

    return () => {
      setWorkoutTitle("")
      setWorkoutDescription("")
      setLevel("")
      setEnvironement("")
    }
  }, [selectedParentNodeData]);

  const RenderDisabledInputs = () => {
    if (workoutParentList?.length) {
      return (
        <>
          <TextField
            disabled
            autoFocus
            fullWidth
            label={commontext.videoType}
            variant="standard"
            defaultValue={capFirst(mainWorkout?.title)}
            sx={{ mt: 3, display: "block" }}
            className={classes.uploadModalDisabledFieldStyle}
          />
          {
            mainWorkout?.type == "normal" ? !mainEnvWorkout ?
              <TextField
                fullWidth
                autoFocus
                label={commontext.levelName}
                variant="standard"
                placeholder={commontext.newLevelPlaceholder}
                value={level}
                onChange={event => setLevel(event.target.value)}
                sx={{ mt: 3.5, display: "block" }}
                inputProps={{
                  maxlength: CHARACTER_LIMIT * 2,
                }}
                className={classes.uploadModalActiveFieldStyle}
                error={values.isLevelError}
                helperText={
                  values.isLevelError ? commontext.pleaseEnterLevel : ""
                }
                onFocus={() => {
                  handleChangeValue('isLevelError', false);
                }}
                onBlur={() => {
                  if (isEmptyOrNull(level)) {
                    handleChangeValue("isLevelError", true);
                  }
                }}
              />
              :
              <TextField
                disabled
                autoFocus
                fullWidth
                label={commontext.chooseLevel}
                variant="standard"
                defaultValue={capFirst(mainEnvWorkout?.title)}
                sx={{ mt: 3.5, display: "block" }}
                className={classes.uploadModalDisabledFieldStyle}
              />
              : null
          }
          {
            mainWorkout?.type == "normal" && mainEnvWorkout ? !subEnvWorkout ?
              <TextField
                autoFocus
                fullWidth
                label={commontext.environmentName}
                variant="standard"
                placeholder={commontext.newEnvironmentPlaceholder}
                value={enviornment}
                onChange={event => setEnvironement(event.target.value)}
                sx={{ mt: 3.5, display: "block" }}
                inputProps={{
                  maxlength: CHARACTER_LIMIT * 2,
                }}
                className={classes.uploadModalActiveFieldStyle}
                error={values.isEnvionmentError}
                helperText={
                  values.isEnvionmentError ? commontext.pleaseEnterEnvironment : ""
                }
                onFocus={() => {
                  handleChangeValue('isEnvionmentError', false);
                }}
                onBlur={() => {
                  if (isEmptyOrNull(enviornment)) {
                    handleChangeValue("isEnvionmentError", true);
                  }
                }}
              />
              :
              <TextField
                disabled
                autoFocus
                fullWidth
                label={commontext.chooseEnv}
                variant="standard"
                defaultValue={capFirst(subEnvWorkout?.title)}
                sx={{ mt: 3.5, display: "block" }}
                className={classes.uploadModalDisabledFieldStyle}
              />
              : null
          }
          {showTitleAndDescription ?
            <>
              <TextField
                autoFocus
                fullWidth
                label={commontext.workoutTitle}
                variant="standard"
                placeholder={commontext.newWorkoutPlaceholder}
                value={workoutTitle}
                onChange={event => setWorkoutTitle(event.target.value)}
                sx={{ mt: 3.5, display: "block" }}
                inputProps={{
                  maxlength: CHARACTER_LIMIT,
                }}
                className={classes.uploadModalActiveFieldStyle}
                error={values.isTitleError}
                helperText={
                  values.isTitleError ? commontext.pleaseEnterWorkoutTitle : ""
                }
                onFocus={() => {
                  handleChangeValue("isTitleError", false);
                }}
                onBlur={() => {
                  if (isEmptyOrNull(workoutTitle))
                    handleChangeValue("isTitleError", true);
                }}
              />

              <TextField
                fullWidth
                label={commontext.workoutDescription}
                variant="standard"
                placeholder={commontext.newWorkoutDescription}
                value={workoutDescription}
                onChange={event => setWorkoutDescription(event.target.value)}
                sx={{ mt: 3.5, display: "block" }}
                inputProps={{
                  maxlength: CHARACTER_LIMIT * 2,
                }}
                className={classes.uploadModalActiveFieldStyle}
                error={values.isDescriptionError}
                helperText={
                  values.isDescriptionError
                    ? commontext.pleaseEnterWorkoutDescription
                    : ""
                }
                onFocus={() => {
                  handleChangeValue("isDescriptionError", false);
                }}
                onBlur={() => {
                  if (isEmptyOrNull(workoutDescription))
                    handleChangeValue("isDescriptionError", true);
                }}
              />
            </>
            : null
          }
        </>
      );
    } else {
      return null;
    }
  };
  const handleChangeValue = (prop, value) => {
    setValues({ ...values, [prop]: value });
  };

  const getDisabledStatus = () => {

    if (mainWorkout?.type == "special") {
      if (workoutTitle != "" && workoutDescription != "") {
        return false;
      }
      else {
        return true;
      }
    }
    else if (mainWorkout?.type == "normal" && !mainEnvWorkout) {
      if (level != "") {
        return false;
      }
      else {
        return true;
      }
    }
    else if (mainWorkout?.type == "normal" && mainEnvWorkout && !subEnvWorkout) {
      if (enviornment != "") {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      if (workoutTitle != "" && workoutDescription != "") {
        return false;
      }
      else {
        return true;
      }
    }
  };

  const onClickAddWorkout = () => {

    let workoutData = {}
    if (mainWorkout?.type == "special") {
      workoutData.workoutTitle = workoutTitle;
      workoutData.workoutDescription = workoutDescription;
    }
    else if (mainWorkout?.type == "normal" && !mainEnvWorkout) {
      workoutData.level = level;
    }
    else if (mainWorkout?.type == "normal" && mainEnvWorkout && !subEnvWorkout) {
      workoutData.enviornment = enviornment;
    }
    else {
      workoutData.workoutTitle = workoutTitle;
      workoutData.workoutDescription = workoutDescription;
    }
    props.onAddNewWorkout(workoutData)
    setTimeout(() => {
      setWorkoutTitle("")
      setWorkoutDescription("")
      close()
    }, 200);
  }

  const classes = useStyles();
  return (
    <Box sx={{ ml: "auto" }}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="upload-video-dialog"
        fullWidth
        maxWidth="md"
        onBackdropClick="false"
      >
        <Box sx={{ px: 3, pt: 3, pb: 5 }}>
          <DialogContent className={classes.uploadVideoContent}>
            <DialogTitle id="upload-video-dialog" sx={{ fontSize: 31 }}>
              <Grid className={classes.dialogCloseBtn}>
                <Typography variant="div">{headerTitle}
                </Typography>
                <IconButton onClick={close}>
                  <AllIcons.CloseIcon
                    sx={{
                      color: theme => theme.palette.common.black,
                      fontSize: 35,
                    }}
                  />
                </IconButton>
              </Grid>
            </DialogTitle>
            {RenderDisabledInputs()}
            <Box>
              <Button
                component="label"
                variant="outlined"
                sx={{
                  px: 7,
                  mt: 6,
                  backgroundColor: theme =>
                    getDisabledStatus()
                      ? theme.palette.disabledButton.btnBg
                      : theme.palette.common.black,
                  opacity: getDisabledStatus() ? 0.4 : 1,
                  color: `${getDisabledStatus()
                    ? theme.palette.common.black
                    : theme.palette.common.white
                    } !important`,
                  boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.25)",
                }}
                disabled={getDisabledStatus()}
                onClick={onClickAddWorkout}
              >
                {commontext.submit}
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}
